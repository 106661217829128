<template>
  <div class="scroll_c">
    <div class="scroll_type_a flex-row" v-if="isReversed">
        <img class="image_icon" v-for="(item, index) in items" :key="index" :src="item"/>
    </div>
    <div class="scroll_type_b flex-row" v-else>
        <img class="image_icon2" v-for="(item, index) in reversedItems" :key="index" :src="item"/>
      </div>
  </div>
</template>
<script setup>

import {defineProps, onMounted, ref} from "vue";


defineProps({
  isReversed: {
    type: Boolean,
    default: true
  }
})

onMounted(function () {
})

const pic_list = ref([
  require("./../../assets/pic/image_photo01.png"),
  require("./../../assets/pic/image_photo02.png"),
  require("./../../assets/pic/image_photo03.png"),
  require("./../../assets/pic/image_photo04.png"),
  require("./../../assets/pic/image_photo05.png"),
  require("./../../assets/pic/image_photo06.png"),
  require("./../../assets/pic/image_photo07.png"),
  require("./../../assets/pic/image_photo08.png"),
  require("./../../assets/pic/image_photo09.png"),
  require("./../../assets/pic/image_photo10.png"),
  require("./../../assets/pic/image_photo11.png"),
  require("./../../assets/pic/image_photo12.png"),
  require("./../../assets/pic/image_photo13.png"),
  require("./../../assets/pic/image_photo14.png"),

  require("./../../assets/pic/image_photo01.png"),
  require("./../../assets/pic/image_photo02.png"),
  require("./../../assets/pic/image_photo03.png"),
  require("./../../assets/pic/image_photo04.png"),
  require("./../../assets/pic/image_photo05.png"),
  require("./../../assets/pic/image_photo06.png")
])

const reversedItems = pic_list.value.reverse()

const items = pic_list.value


</script>
<style scoped lang="scss">

.scroll_c{
  width: 100%;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;

}

.scroll_type_a  {
  height: 2.3rem;
  position: relative;
  overflow: hidden;
  margin-top: 0.24rem;
  .image_icon {
    width: 2.3rem;
    height: 2.3rem;
    flex-shrink: 0;
    margin-left: 0.12rem;
    margin-right: 0.12rem;
    border-radius: 0.24rem;
    animation: marquee 60s linear infinite;
  }
}
.scroll_type_b {
  height: 2.3rem;
  position: relative;
  margin-left: -100%;

  .image_icon2 {
    width: 2.3rem;
    height: 2.3rem;
    flex-shrink: 0;
    margin-left: 0.12rem;
    margin-right: 0.12rem;
    border-radius: 0.24rem;
    animation: marquee2 60s linear infinite;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-800%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes marquee2 {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(800%);
  }
  100% {
    transform: translateX(0%);
  }

}

</style>
