<template>
  <div class="anim_conner">
    <div class="image-wrapper_1 flex-row">
      <img class="image_bank" src="./../../assets/banks/image_logo01.png"/>
      <img class="image_bank" src="./../../assets/banks/image_logo02.png"/>
      <img class="image_bank" src="./../../assets/banks/image_logo03.png"/>
      <img class="image_bank" src="./../../assets/banks/image_logo04.png"/>
      <img class="image_bank" src="./../../assets/banks/image_logo05.png"/>
      <img class="image_bank" src="./../../assets/banks/image_logo06.png"/>
      <img class="image_bank" src="./../../assets/banks/image_logo07.png"/>
      <img class="image_bank" src="./../../assets/banks/image_logo08.png"/>
      <img class="image_bank" src="./../../assets/banks/image_logo09.png"/>
      <img class="image_bank" src="./../../assets/banks/image_logo10.png"/>
      <img class="image_bank" src="./../../assets/banks/image_logo11.png"/>
      <img class="image_bank" src="./../../assets/banks/image_logo12.png"/>
    </div>

    <div class="cover_c flex-row justify-between">
      <div class="cover_left flex-row">
        <div class="cover_white"/>
        <div class="cover_a"/>
      </div>
      <div class="cover_right flex-row justify-between" >
        <div class="cover_b"/>
        <div class="cover_white"/>
      </div>
    </div>
  </div>
</template>
<script setup>

</script>
<style scoped lang="scss">

.anim_conner{
  width: 100%;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
}

.cover_c {
  width: 100%;
  height:100%;
  position: absolute;
  top: 0;

  .cover_right{
    width: 2rem;
    height: 100%;
    position: relative;
    .cover_white {
      width: 1rem;
      height: 100%;
      background-color: white;
    }

    .cover_b{
      width: 1rem;
      height: 100%;
      background: linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0));
    }
  }
  .cover_left{
    width: 2rem;
    height: 100%;
    position: relative;
    .cover_white {
      width: 1rem;
      height: 100%;
      background-color: white;
    }
    .cover_a{
      width: 1rem;
      height: 100%;
      background: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
    }
  }

}

.image-wrapper_1 {
  animation: marquee_mobi 60s linear infinite;
}

.image_bank {
  width: auto;
  height: 2.05rem;
  flex-shrink: 0;
  margin-left: 0.65rem;
  margin-right: 0.65rem;
}

@keyframes marquee_mobi {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-300%);
  }
}

</style>
