<template>
  <div class="page flex-col">
    <PageHeader current_page="trading" class="header"/>
    <div class="group_box_1 flex-col">
      <div class="text-wrapper_head flex-row">
        <span class="text-wrapper_head_text">TRADING</span>
      </div>
    </div>
    <div class="text-wrapper_3 flex-col animate__animated animate__fadeInUp">
      <span class="text_8">TRADING</span>
      <span class="text_9">Our&nbsp;commodities</span>
    </div>
    <TradingSlideView class="slide_c animate__animated animate__fadeInUp animate__delay-1s" :index="currentPage"/>
    <span class="text_16">Our&nbsp;partners</span>
    <span class="text_17">We achieved strategic international partnership with world leading companies</span>
    <div class="image-wrapper_1 flex-row">
      <SlideBankIconView/>
    </div>
    <PageFooter class="footer"/>
  </div>
</template>

<script setup>
import PageFooter from "@/components/PageFooter.vue";
import PageHeader from "@/components/PageHeader.vue";
import SlideBankIconView from "@/components/comment/SlideBankIconView.vue";
import TradingSlideView from "@/views/trading/TradingSlideView.vue";
import {useRoute} from "vue-router";
import {computed, onMounted, onUpdated, ref} from "vue";

const index = ref(0)

onMounted(function () {
  const route = useRoute()
  if (route.query) {
    if (route.query.type === "CrudeOil" ) {
      index.value = 0
    } else if (route.query.type === "FuelOil") {
      index.value = 2
    } else if (route.query.type === "LNG") {
      index.value = 1
    } else if (route.query.type === "PetroleumProducts") {
      index.value = 3
    } else {
      index.value = 0
    }
  }
})

onUpdated(function () {
  const route = useRoute()
  if (route.query) {
    if (route.query.type === "CrudeOil" ) {
      index.value = 0
    } else if (route.query.type === "FuelOil") {
      index.value = 2
    } else if (route.query.type === "LNG") {
      index.value = 1
    } else if (route.query.type === "PetroleumProducts") {
      index.value = 3
    } else {
      index.value = 0
    }
  }
})

const currentPage = computed(function () {
  return index.value
})


</script>

<style scoped lang="scss">
.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  width: 19.20rem;
  overflow: hidden;
  .group_box_1 {
    background-image: url("./../assets/image/adout_head_bg.png");
    width: 100%;
    height: 4.00rem;
    background-size: cover;
    .text-wrapper_head {
      width: 11.12rem;
      height: 1.68rem;
      margin-top: 1.18rem;
      background-image: url("./../assets/image/text_img_stroke_TRADING.png");
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      align-self: center;
      .text-wrapper_head_text {
        overflow-wrap: break-word;
        color: rgba(234, 88, 18, 1);
        font-size: 0.90rem;
        text-transform: uppercase;
        font-family: Montserrat-Black;
        text-align: center;
        white-space: nowrap;
        line-height: 1.10rem;
        position: absolute;
        bottom: -0.2rem;
        width: 100%;
      }
    }
  }
  .text-wrapper_3 {
    width: 100%;
    position: relative;
    margin-top: 0.5rem;
    .text_8 {
      overflow-wrap: break-word;
      color: rgba(89, 89, 89, 1);
      font-size: 0.18rem;
      font-family: Montserrat-Regular;
      text-align: left;
      line-height: 0.65rem;
      align-self: center;
    }

    .text_9 {
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 0.56rem;
      text-transform: uppercase;
      font-family: Montserrat-SemiBold;
      white-space: nowrap;
      line-height: 0.35rem;
      align-self: center;
    }
  }
}
.text_16 {
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.32rem;
  text-transform: uppercase;
  font-family: Montserrat-SemiBold;
  text-align: center;
  white-space: nowrap;
  line-height: 0.39rem;
  margin-top: 1.58rem;
}

.text_17 {
  overflow-wrap: break-word;
  color: rgba(71, 71, 71, 1);
  font-size: 0.16rem;
  font-family: Montserrat-Regular;
  text-align: center;
  white-space: nowrap;
  line-height: 0.19rem;
  margin-top: 0.24rem;
}

.image-wrapper_1 {
  width: 100%;
  justify-content: space-between;
  margin-top: 0.79rem;
}

.footer{
  margin-top: 1rem;
}

</style>
