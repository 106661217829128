<template>
  <div class="slide_block_1 flex-col">
    <div class="box_5 flex-row">
<!--      <img class="block_2" :src="image">-->
      <div class="text-wrapper_3 flex-col">
        <span class="text_10">{{props.title}}</span>
        <span class="text_11">{{props.name}}</span>
      </div>
    </div>
    <span class="text_12">{{props.desc}}</span>
  </div>

</template>
<script setup>

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  desc: {
    type: String,
    default: ''
  },
  image: {
    type: String,
    default: require('./../../assets/image/team_pic_cfo.png')
  }
})

</script>

<style scoped lang="scss">
.slide_block_1 {
  background-color: rgba(243, 251, 245, 1);
  width: 8.92rem;
  //height: 4.28rem;
  overflow: hidden;

  .box_5 {
    height: 0.97rem;
    margin: 0.31rem 0 0 0.62rem;
    .block_2 {
      border-radius: 1.64rem;
      width: 0.92rem;
      height: 0.92rem;
      border: 0.04rem solid rgba(255, 255, 255, 1);
    }
    .text-wrapper_3 {
      height: 0.97rem;
      .text_10 {
        overflow-wrap: break-word;
        color: rgba(89, 89, 89, 1);
        font-size: 0.18rem;
        font-family: Montserrat-Regular;
        text-align: left;
        line-height: 0.65rem;
      }

      .text_11 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 0.40rem;
        font-family: Montserrat-SemiBold;
        text-align: left;
        white-space: nowrap;
        line-height: 0.65rem;
        margin-top: -0.33rem;
      }
    }
  }
  .text_12 {
    width: 7.68rem;
    height: 2.76rem;
    overflow-wrap: break-word;
    color: rgba(89, 89, 89, 1);
    font-size: 0.16rem;
    font-family: Montserrat-Regular;
    text-align: left;
    line-height: 0.32rem;
    margin: 0.13rem 0 0.31rem 0.62rem;
    overflow: scroll;
  }
}


</style>
