<template>
  <teleport to="body">
    <div class="slide_mobi_c" v-if="isSlideMarketViewShow">
      <div class="slide_top_c flex-col">
        <div class="menu_c">
          <div class="menu_top_c flex-row justify-between">
            <div class="box_left flex-row justify-between">
              <img class="logo_img_1" src="../assets/image/icon.png"/>
              <img class="logo_img_2" src="../assets/image/logo_img_3.png"/>
            </div>
            <div class="box_right" @click="onHideViewClicked">
              <img class="logo_close" src="../assets/image/logo_close.png"/>
            </div>
          </div>
          <div class="menu_item_c flex-col">
            <div class="line"/>
            <GapView gap="0.6"/>
            <span :class="isHomePage? 'menu_item_2': 'menu_item'" @click="OnclickHome">HOME</span>
            <SlideItem title="ABOUT US" :is-about-page="isAboutPage" :is-history-page="isHistoryPage" :is-team-page="isteamPage" @on_click_history="OnclickGrowth" @on_click_team="OnclickTeam" @on_click_company="OnclickAbout"/>
            <span :class="isTradingPage? 'menu_item_2': 'menu_item'" @click="OnclickTrading">TRADING</span>
            <span :class="isCareersPage? 'menu_item_2': 'menu_item'" @click="OnclickCareers">CAREERS</span>
            <span :class="isContactPage? 'menu_item_2': 'menu_item'" @click="OnclickContact">CONTACT US</span>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup>
import {computed, ref} from 'vue'
import GapView from "@/components/comment/GapView.vue";
import SlideItem from "@/componentsPhone/comment/SlideItem.vue";
import { useStore } from 'vuex'
const store = useStore()
import {onMounted} from "vue"
const currentPage = ref('homePage')
onMounted(function () {
  currentPage.value = store.state.currentMobiPage
})

const isHomePage = computed(function () {
  return store.state.currentMobiPage  === 'homePage'
})
const isAboutPage = computed(function () {
  return store.state.currentMobiPage  === 'aboutPage'
})
const isteamPage = computed(function () {
  return store.state.currentMobiPage  === 'teamPage'
})
const isHistoryPage = computed(function () {
  return store.state.currentMobiPage  === 'historyPage'
})
const isTradingPage = computed(function () {
  return store.state.currentMobiPage  === 'tradingPage'
})
const isCareersPage = computed(function () {
  return store.state.currentMobiPage  === 'careersPage'
})
const isContactPage = computed(function () {
  return store.state.currentMobiPage  === 'contactPage'
})

const isSlideMarketViewShow = computed(function () {
  return store.state.slideIsShow
})


function onHideViewClicked () {
  store.commit('hideSlideView')
}

function OnclickHome() {
  onHideViewClicked()
  window.location.href = './#/homePage'
}
function OnclickAbout() {
  onHideViewClicked()
  window.location.href = './#/aboutPage'
}
function OnclickTrading() {
  onHideViewClicked()
  window.location.href = './#/tradingPage'
}
function OnclickCareers() {
  onHideViewClicked()
  window.location.href = './#/careersPage'
}
function OnclickContact() {
  onHideViewClicked()
  window.location.href = './#/contactPage'
}
function OnclickTeam() {
  onHideViewClicked()
  window.location.href = './#/teamPage'
}
function OnclickGrowth() {
  onHideViewClicked()
  window.location.href = './#/historyPage'
}


</script>

<style scoped lang="scss">
.slide_mobi_c {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  margin:0 auto;
  width: 100vw;
  height: 100vh;
  .slide_top_c{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    background-color: #FFFFFF;
    z-index: 0;
    .menu_c {
      width: 100%;
      height: 100%;
      padding-left: 0.82rem;
      padding-right: 0.82rem;
      display: flex;
      flex-direction: column;
      .menu_top_c {
        width: 100%;
        height: 4.1rem;
        .box_left {
          width: 7.25rem;
          height: 2.05rem;
          align-self: center;
          cursor: pointer;
          .logo_img_1 {
            width: 1.77rem;
            height: 2.05rem;
            align-self: center;
          }
          .logo_img_2 {
            width: 4.84rem;
            height: 0.71rem;
            margin-left: 0.61rem;
            align-self: center;
          }
        }
        .box_right{
          width: 0.97rem;
          height: 0.97rem;
          align-self: center;
          cursor: pointer;
          .logo_close{
            width: 100%;
            height: 100%;
            background-size: cover;
          }
        }
      }
      .menu_item_c{
        width: 100%;
        .menu_item{
          width: 100%;
          line-height: 2.60rem;
          cursor: pointer;
          overflow-wrap: break-word;
          color: rgba(51, 51, 51, 1);
          font-size: 0.82rem;
          font-family: Montserrat-Regular;
          white-space: nowrap;
        }
        .menu_item_2{
          width: 100%;
          line-height: 2.60rem;
          cursor: pointer;
          overflow-wrap: break-word;
          color: rgba(51, 51, 51, 1);
          font-size: 0.82rem;
          font-family: Montserrat-Bold;
          white-space: nowrap;
        }
      }

    }

  }
}
.line {
  background-color: rgba(222, 222, 222, 0.8);
  width: 100%;
  height: 0.05rem;
  align-self: center;
}
</style>
