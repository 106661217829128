<template>
  <div class="page flex-col">
    <PageHeader current_page="contact" class="header"/>
    <div class="group_box_1 flex-col">
      <div class="text-wrapper_head flex-row">
        <span class="text-wrapper_head_text">CONTACT US</span>
      </div>
    </div>
    <div class="text-group_1 flex-col animate__animated animate__fadeInUp">
      <span class="text_8">CONTACT&nbsp;US</span>
      <span class="text_9">LET’S&nbsp;TALK</span>
      <span class="text_10">Got a question? Want to learn more about us? Get in touch.</span>
    </div>
    <div class="list_1 flex-row justify-between animate__animated animate__fadeInUp animate__delay-1s">
      <div class="list-items_1 flex-col" v-for="(item, index) in loopData0" :key="index">
        <img class="image_3" :src="item.image"/>
        <div class="text-group_2 flex-col justify-between">
          <span class="text_11" v-html="item.title"></span>
          <span class="text_12" v-html="item.desc"></span>
        </div>
      </div>
    </div>

    <div class="group_3 flex-col animate__animated animate__fadeInUp animate__delay-2s">
<!--      <GoogleMapView/>-->
      <div class="box_3 flex-col"></div>
    </div>
    <PageFooter class="footer"/>
  </div>
</template>

<script setup>

import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

const loopData0= [
  {
    title: 'Our&nbsp;Number',
    desc: '+65&nbsp;6226&nbsp;1666',
    image: require('./../assets/image/contact_icon_call.png'),
  },
  {
    title: 'Our&nbsp;Email',
    desc: 'info&#64;petrosunrich.com',
    image: require('./../assets/image/contact_icon_email.png'),
  },
  {
    title: 'Our&nbsp;Address',
    desc: '6&nbsp;Shenton&nbsp;Way,&nbsp;OUE&nbsp;Downtown&nbsp;2<br/>#20-11,&nbsp;Singapore&nbsp;068809',
    image: require('./../assets/image/contact_icon_location.png'),
  },
]
</script>

<style scoped lang="scss">
.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  width: 19.20rem;
  overflow: hidden;
  .group_box_1 {
    background-image: url("./../assets/image/adout_head_bg.png");
    width: 100%;
    height: 4.00rem;
    background-size: cover;
    .text-wrapper_head {
      width: 11.63rem;
      height: 1.68rem;
      margin-top: 1.18rem;
      background-image: url("./../assets/image/text_img_stroke_CONTACT.png");
      background-size: cover;
      position: relative;
      align-self: center;
      .text-wrapper_head_text {
        overflow-wrap: break-word;
        color: rgba(234, 88, 18, 1);
        font-size: 0.90rem;
        text-transform: uppercase;
        font-family: Montserrat-Black;
        text-align: center;
        white-space: nowrap;
        line-height: 1.10rem;
        position: absolute;
        bottom: -0.2rem;
        width: 100%;
      }
    }
  }

  .text-group_1 {
    width: 100%;
    margin-top: 0.5rem;
    .text_8 {
      overflow-wrap: break-word;
      color: rgba(89, 89, 89, 1);
      font-size: 0.18rem;
      text-transform: uppercase;
      font-family: Montserrat-Regular;
      line-height: 0.65rem;
      text-align: center;
    }

    .text_9 {
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 0.56rem;
      font-family: Montserrat-SemiBold;
      text-align: center;
      white-space: nowrap;
      line-height: 0.65rem;
      margin: -0.17rem 0 0 0;
    }

    .text_10 {
      width: 70%;
      overflow-wrap: break-word;
      color: rgba(89, 89, 89, 1);
      font-size: 0.16rem;
      font-family: Montserrat-Regular;
      text-align: center;
      line-height: 0.32rem;
      margin-top: 0.24rem;
      align-self: center;
    }
  }

  .list_1 {
    width: 11.09rem;
    height: 1.36rem;
    margin: 0.48rem 0 0 0;
    align-self: center;
    .list-items_1 {
      position: relative;
      height: 1.36rem;
      width: 2.73rem;
      .image_3 {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: 0.80rem;
        height: 0.80rem;
      }

      .text-group_2 {
        margin-top: 1.04rem;
        text-align: center;
        .text_11 {
          overflow-wrap: break-word;
          color: rgba(51, 51, 51, 1);
          font-size: 0.20rem;
          font-family: Montserrat-SemiBold;
          text-align: center;
          white-space: nowrap;
          line-height: 0.32rem;
        }

        .text_12 {
          overflow-wrap: break-word;
          color: rgba(89, 89, 89, 1);
          font-size: 0.16rem;
          font-family: Montserrat-Regular;
          text-align: center;
          line-height: 0.32rem;
          margin-top: 0.08rem;
        }
      }

    }

  }

  .group_3 {
    width: 100%;
    margin-top: 0.83rem;

    .box_3 {
      border-radius: 14px;
      background-image: url("./../assets/image/contact_map.png");
      width: 14.20rem;
      height: 6.16rem;
      align-self: center;
      background-size: cover;
    }
  }
}

.footer {
  margin-top: 0.8rem;
}

</style>
