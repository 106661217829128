<template>
  <div class="page flex-col">
    <PageHeader/>
    <div class="group_box_1 flex-col">
      <div class="text-wrapper_head flex-col">
        <div class="text-wrapper_head_img"/>
        <span class="text-wrapper_head_text">TRADING</span>
      </div>
    </div>
    <div class="text-wrapper_3 flex-col">
      <span class="text_8">TRADING</span>
      <span class="text_9">Our&nbsp;commodities</span>
    </div>
    <TradingSlideView class="slide_c" :index="currentPage"/>
    <span class="text_16">Our partners</span>
    <span class="text_17">We achieved strategic international partnership with <br/>world leading companies</span>
    <div class="image-wrapper_1 flex-row">
      <SlideBankIconView/>
    </div>
    <PageFooter/>
  </div>
</template>

<script setup>
import PageFooter from "@/componentsPhone/PageFooter.vue";
import PageHeader from "@/componentsPhone/PageHeader.vue";
import SlideBankIconView from "@/componentsPhone/comment/SlideBankIconView.vue";
import TradingSlideView from "@/componentsPhone/comment/TradingSlideView.vue";
import {useRoute} from "vue-router";
import {computed, onMounted, onUpdated, ref} from "vue";
import { useStore } from 'vuex'
const store = useStore()
onMounted(function () {
  store.commit('changeCurrentMobiPage', 'tradingPage')
})

const index = ref(0)

onMounted(function () {
  const route = useRoute()
  if (route.query) {
    if (route.query.type === "CrudeOil" ) {
      index.value = 0
    } else if (route.query.type === "FuelOil") {
      index.value = 2
    } else if (route.query.type === "LNG") {
      index.value = 1
    } else if (route.query.type === "PetroleumProducts") {
      index.value = 3
    } else {
      index.value = 0
    }
  }
})

onUpdated(function () {
  const route = useRoute()
  if (route.query) {
    if (route.query.type === "CrudeOil" ) {
      index.value = 0
    } else if (route.query.type === "FuelOil") {
      index.value = 2
    } else if (route.query.type === "LNG") {
      index.value = 1
    } else if (route.query.type === "PetroleumProducts") {
      index.value = 3
    } else {
      index.value = 0
    }
  }
})

const currentPage = computed(function () {
  return index.value
})


</script>

<style scoped lang="scss">
.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  width: 19.20rem;
  overflow: hidden;
  .group_box_1 {
    background-image: url("./../assets/image/adout_head_bg.png");
    width: 100%;
    height: 10.34rem;
    background-size: auto 100%;
    background-position: center;
    .text-wrapper_head {
      width: 100%;
      height: 2.56rem;
      margin-top: 5.32rem;
      background-size: cover;
      position: relative;
      .text-wrapper_head_img {
        width: 16.23rem;
        height: 2.56rem;
        align-self: center;
        background-image: url("./../assets/image/text_img_stroke_TRADING.png");
        background-size: auto 100%;
      }
      .text-wrapper_head_text {
        overflow-wrap: break-word;
        color: rgba(234, 88, 18, 1);
        font-size: 1.69rem;
        text-transform: uppercase;
        font-family: Montserrat-Black;
        white-space: nowrap;
        line-height: 2.05rem;
        position: absolute;
        align-self: center;
        bottom: -0.4rem;
      }
    }
  }
  .text-wrapper_3 {
    width: 100%;
    position: relative;
    margin-top: 0.5rem;
    .text_8 {
      overflow-wrap: break-word;
      color: rgba(89, 89, 89, 1);
      font-size: 0.92rem;
      line-height: 1.13rem;
      font-family: Montserrat-Regular;
      margin-top: 1.43rem;
    }

    .text_9 {
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 1.74rem;
      text-transform: uppercase;
      font-family: Montserrat-SemiBold;
      white-space: nowrap;
      line-height: 1.35rem;
      margin-top: 0.41rem;
    }
  }
  .slide_c {
    margin-top: 1.23rem;
  }
}
.text_16 {
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 1.43rem;
  text-transform: uppercase;
  font-family: Montserrat-SemiBold;
  text-align: center;
  white-space: nowrap;
  line-height: 1.74rem;
  margin-top: 2.46rem;
}

.text_17 {
  overflow-wrap: break-word;
  color: rgba(71, 71, 71, 1);
  font-size: 0.61rem;
  font-family: Montserrat-Regular;
  text-align: center;
  white-space: nowrap;
  line-height: 0.77rem;
  margin-top: 0.41rem;
}

.image-wrapper_1 {
  width: 100%;
  justify-content: space-between;
  margin-top: 1.23rem;
  margin-bottom: 2.46rem;
}

</style>
