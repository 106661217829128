<template>
  <div class="group_1 flex-col" v-on:mouseenter="onMouseOver" v-on:mouseout="onMouseOut">
    <span :class="isCurrent? 'text_1_bold': 'text_1'">{{title}}</span>
  </div>
</template>

<script setup>

import {computed, defineProps, ref} from 'vue'
const props = defineProps({
  is_selected: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  }
})

const isMouseOver = ref(false)

const isCurrent = computed(function () {
  if (props.is_selected) {
    return true
  } else if (isMouseOver.value) {
    return true
  } else {
    return false
  }

})

// const widthComputed = computed(function () {
//   return props.width + 'rem'
// })

function onMouseOver () {
  isMouseOver.value = true
}
function onMouseOut () {
  isMouseOver.value = false
}

</script>


<style scoped lang="scss">
.group_1 {
  height: 0.25rem;
  cursor: pointer;
  .text_1 {
    font-family: Montserrat-Regular;
    font-weight: 400;
    font-size: 0.16rem;
    color: #595959;
    line-height: 0.19rem;
    text-align: left;
    font-style: normal;
  }
  .text_1_bold {
    font-family: Montserrat-SemiBold;
    font-weight: 400;
    font-size: 0.16rem;
    color: #333333;
    line-height: 0.19rem;
    text-align: left;
    font-style: normal;
    cursor: pointer;
  }

}

</style>
