<template>
  <transition name="fade">
    <div class="header_box_1 flex-row justify-between" :style="currentHeaderStyle" v-show="isNavbarVisible">
      <div class="box_left flex-row justify-between" @click="OnclickHome">
        <img class="logo_img_1" src="../assets/image/icon.png"/>
        <img class="logo_img_2" :src="headImgStyle"/>
      </div>
      <div class="group_1 flex-row justify-between">
        <MenuHeaderItem :is_selected="isHomePage" title="HOME" :width="0.6" :is-top="isTop" @click="OnclickHome" v-on:mouseleave="onMouseOut"/>
        <GapView :gap="0.45"/>
        <div class="menu2 flex-row justify-center" v-on:mouseover="onMouseOver">
          <MenuHeaderAbout :is_selected="isAboutUsPage" title="ABOUT US" :width="0.96" :is-top="isTop" @click="OnclickAbout"/>
        </div>
        <GapView :gap="0.45"/>
        <MenuHeaderItem :is_selected="isTradingPage" title="TRADING" :width="0.86" :is-top="isTop" @click="OnclickTrading" v-on:mouseleave="onMouseOut"/>
        <GapView :gap="0.45"/>
        <MenuHeaderItem :is_selected="isCareersPage" title="CAREERS" :width="0.87 " :is-top="isTop" @click="OnclickCareers" v-on:mouseleave="onMouseOut"/>
        <GapView :gap="0.45"/>
        <MenuHeaderItem :is_selected="isContactPage" title="CONTACT US" :width="1.21" :is-top="isTop" @click="OnclickContact" v-on:mouseleave="onMouseOut"/>
        <div class="group_2 flex-col" v-show="isShowSecondPage" v-on:mouseleave="onMouseOut">
          <MenuHeaderItem2 :is_selected="isAboutPage" title="Company Profile" @click="OnclickAbout"/>
          <MenuHeaderItem2 :is_selected="isGrowthPage" title="History" @click="OnclickGrowth"/>
          <MenuHeaderItem2 :is_selected="isTeamPage" title="Our team" @click="OnclickTeam"/>
        </div>
      </div>
    </div>

  </transition>
</template>

<script setup>
import MenuHeaderItem from "./comment/MenuHeaderItem.vue";

import {computed, defineProps, onMounted, onUnmounted, ref} from 'vue'
import GapView from "@/components/comment/GapView.vue";
import MenuHeaderItem2 from "@/components/comment/MenuHeaderItem2.vue";
import MenuHeaderAbout from "@/components/comment/MenuHeaderAbout.vue";
const props = defineProps({
  current_page: {
    type: String,
    default: 'index'
  }
})

const isHomePage = computed(function () {
  return props.current_page === 'home'
})
const isAboutUsPage = computed(function () {
  return isShowSecondPage.value || props.current_page === 'about' || props.current_page === 'team' || props.current_page === 'history'
})
const isTradingPage = computed(function () {
  return props.current_page === 'trading'
})
const isCareersPage = computed(function () {
  return props.current_page === 'careers'
})
const isContactPage = computed(function () {
  return props.current_page === 'contact'
})
const isAboutPage = computed(function () {
  return props.current_page === 'about'
})
const isTeamPage = computed(function () {
  return props.current_page === 'team'
})
const isGrowthPage = computed(function () {
  return props.current_page === 'history'
})
const isMouseOver = ref(false)
function onMouseOver () {
  isMouseOver.value = true
}
function onMouseOut () {
  isMouseOver.value = false
}

const isShowSecondPage= computed(function () {
  return isMouseOver.value
})

function OnclickHome() {
  window.location.href = './#/home'
}
function OnclickAbout() {
  window.location.href = './#/about'
}
function OnclickTrading() {
  window.location.href = './#/trading'
}
function OnclickCareers() {
  window.location.href = './#/careers'
}
function OnclickContact() {
  window.location.href = './#/contact'
}
function OnclickTeam() {
  window.location.href = './#/team'
}

function OnclickGrowth() {
  window.location.href = './#/history'

}


const isNavbarAtTop = ref(true);
const navbarHeight = 100;

const topValue = ref(0)

const isNavbarVisible = ref(true)

const handleScroll = () => {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  isNavbarAtTop.value = scrollTop <= navbarHeight;
  if (isNavbarAtTop.value) {
    style.value = styleObject1
  } else {
    style.value = styleObject
  }
  if (scrollTop > topValue.value) {
    isNavbarVisible.value = false
  } else {
    isNavbarVisible.value = true
  }
  topValue.value = scrollTop
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

const styleObject ={
  backgroundColor: 'white',
  boxShadow: '0px 0 0.1rem 0px rgba(0, 0, 0, 0.2)'
}

const styleObject1 = {
  backgroundColor: 'transparent',
}

const style = ref({})

const currentHeaderStyle = computed(function () {
  return style.value
})

const isTop = computed(function () {
  return isNavbarAtTop.value
})

const headImgStyle = computed(function () {
  if (isNavbarAtTop.value) {
    return require('./../assets/image/icon_title.png')
  } else {
    return require('./../assets/image/logo_img_3.png')

  }
})

</script>

<style scoped lang="scss">
.header_box_1 {
  width: 100%;
  height: 0.8rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  position: fixed;
  z-index: 100;
}
.box_left {
  cursor: pointer;
}
.logo_img_1 {
  width: 0.46rem;
  height: 0.52rem;
  align-self: center;
}
.logo_img_2 {
  width: 1.29rem;
  height: 0.18rem;
  margin-left: 0.16rem;
  align-self: center;
}
.group_1 {
}

.menu2{
}

.group_2 {
  width: 1.81rem;
  height: 1.53rem;
  margin-top: 0.7rem;
  margin-left: 0.8rem;
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 0.02rem 0.10rem 0px rgba(0,0,0,0.2);
  border-radius: 2px;
  justify-content: space-around;
  padding: 0.12rem 0 0.12rem 0.24rem;
  z-index: 1000;
}


.fade-enter-active, .fade-leave-active {
  transition: all 1s ease;
}
.fade-enter, .fade-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

</style>
