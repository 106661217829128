<template>
  <div class="page flex-col">
    <PageHeader current_page="team" class="header"/>
    <div class="group_box_1 flex-col">
      <div class="text-wrapper_head flex-row">
        <span class="text-wrapper_head_text">Meet&nbsp;Our&nbsp;Team</span>
      </div>
    </div>
    <div class="box_3 flex-col">
      <div class="box_4 flex-row justify-between">
        <div class="group_2 flex-col animate__animated animate__fadeInUp"></div>
        <div class="group_3 flex-col">
          <span class="text_8 animate__animated animate__fadeInUp">TEAM</span>
          <span class="text_9 animate__animated animate__fadeInUp">Meet&nbsp;Our&nbsp;Team</span>
          <TeamSlideView class="team_c animate__animated animate__fadeInUp animate__delay-1s"/>
        </div>
      </div>
      <div class="box_6 flex-col">
        <span class="text_13">Our experienced team of experts can provide you with safe<br/> and efficient energy trading.</span>
        <div class="list_1 flex-row">
          <div class="image-text_1 flex-col" v-for="(item, index) in loopData0" :key="index">
            <div class="text-wrapper_4">
              <img class="text-wrapper_image" :src="item.image">
              <span class="text_14" v-html="item.title"></span>
            </div>
            <span class="text-group_1" v-html="item.desc"></span>
          </div>
        </div>
      </div>
    </div>
    <PageFooter/>
  </div>
</template>

<script setup>

import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import TeamSlideView from "@/views/team/TeamSlideView.vue";

const loopData0 = [
  {
    image: require('./../assets/image/team_pic_2.png'),

    title: 'Trading&nbsp;Team',
    desc: 'Sunrich has a professional trading team consisting of crude oil traders, fuel oil traders, and futures strategy traders. Members of the trading team have over 15 years of industry experience, having worked in large state-owned oil companies or major Trading Houses.'
  },
  {
    image: require('./../assets/image/team_pic_3.png'),
    title: 'Operation&nbsp;Team',
    desc: 'Members of Sunrich\'s operation team all possess over 10 years of relevant professional experience and have worked in large oil companies and major Trading Houses.'
  },
  {
    image: require('./../assets/image/team_pic_4.png'),
    title: 'Finance&nbsp;Team',
    desc: 'Sunrich boasts professional financial personnel, each with over 10 years of professional financial work experience. They are primarily responsible for trade financing, execution, liaising with external audits, banks, and other related tasks.'
  }
]

</script>

<style scoped lang="scss">

.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  width: 19.20rem;
  overflow: hidden;

  .group_box_1 {
    background-image: url("./../assets/image/adout_head_bg.png");
    width: 100%;
    height: 4.00rem;
    background-size: cover;
    .text-wrapper_head {
      width: 6.84rem;
      height: 1.60rem;
      margin-top: 1.18rem;
      background-image: url("./../assets/image/text_img_stroke_team.png");
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      align-self: center;
      .text-wrapper_head_text {
        overflow-wrap: break-word;
        color: rgba(234, 88, 18, 1);
        font-size: 0.90rem;
        text-transform: uppercase;
        font-family: Montserrat-Black;
        text-align: center;
        white-space: nowrap;
        line-height: 1.10rem;
        position: absolute;
        bottom: -0.25rem;
        margin-left: -0.6rem;
        width: 100%;
      }
    }
  }

}

.box_3 {
  width: 100%;
  margin-bottom: 1px;
  .box_4 {
    width: 14.40rem;
    height: 6.16rem;
    overflow: hidden;
    margin-top: 0.8rem;
    align-self: center;
    .group_2 {
      background-image: url("./../assets/image/team_pic_1.png");
      background-size: cover;
      width: 5.48rem;
      height: 6.16rem;
    }
    .group_3 {
      width: 8.92rem;
      height: 6.16rem;
      .text_8 {
        overflow-wrap: break-word;
        color: rgba(89, 89, 89, 1);
        font-size: 0.18rem;
        font-family: Montserrat-Regular;
        text-align: left;
        line-height: 0.65rem;
        margin-top: 0.26rem;
        margin-left: 0.62rem;

      }
      .text_9 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 0.56rem;
        text-transform: uppercase;
        font-family: Montserrat-SemiBold;
        text-align: left;
        white-space: nowrap;
        line-height: 0.65rem;
        margin-top: -0.17rem;
        margin-left: 0.62rem;

      }
      .team_c {
        margin-top: 0.29rem;
      }
    }
  }
}
.box_6 {
  background-color: rgba(243, 251, 245, 1);
  width: 100%;
  height: 7.23rem;
  margin-top: 0.80rem;
  .text_13 {
    width: 70%;
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 0.32rem;
    font-family: Montserrat-SemiBold;
    text-align: center;
    line-height: 0.48rem;
    margin-top: 0.80rem;
    align-self: center;
  }

  .list_1 {
    width: 14.40rem;
    height: 4.32rem;
    justify-content: space-between;
    margin: 0.35rem 0 0.80rem 0;
    align-self: center;
    .image-text_1 {
      background-color: rgba(255, 255, 255, 1);
      border-radius: 0.08rem;
      width: 4.64rem;
      height: 4.32rem;
      margin-right: 0.24rem;
      overflow: hidden;
      .text-wrapper_4 {
        border-radius: 0.08rem 0.08rem 0 0;
        height: 2.04rem;
        position: relative;
        .text-wrapper_image {
          width: 100%;
          height: 2.02rem;
        }
        .text_14 {
          width: 100px;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          position: absolute;
          left: 0.24rem;
          top: 1.46rem;
          font-size: 0.32rem;
          font-family: Montserrat-SemiBold;
          text-align: left;
          white-space: nowrap;
          line-height: 0.34rem;
        }
      }

      .text-group_1 {
        overflow-wrap: break-word;
        color: rgba(89, 89, 89, 1);
        font-size: 0.16rem;
        font-family: Montserrat-Regular;
        text-align: left;
        line-height: 0.32rem;
        margin: 0.18rem 0.18rem  0.18rem  0.24rem;
      }
    }
  }
}

</style>
