<template>
  <div class="footer_c flex-col">
    <div class="footer_block_c flex-col">
      <div class="footer_block_1 flex-col">
        <div class="img_c flex-row" @click="OnclickHome">
          <img class="image_1" src="./../assets/image/icon.png"/>
          <img class="image_2" src="./../assets/image/icon_title.png"/>
        </div>
        <GapView gap="1.54"/>
        <div class="image-text_block flex-row">
          <img class="icon_img" src="./../assets/image/icon_location.png"/>
          <span class="img_text_1">6 Shenton Way, OUE Downtown 2 <br/>#20-11, Singapore 068809</span>
        </div>
        <GapView gap="0.82"/>
        <div class="image-text_block flex-row">
          <img class="icon_img" src="./../assets/image/icon_phone.png"/>
          <span class="img_text_1">+65&nbsp;6226&nbsp;1666</span>
        </div>
        <GapView gap="0.82"/>
        <div class="image-text_block flex-row">
          <img class="icon_img" src="./../assets/image/icon_print.png"/>
          <span class="img_text_1">+65&nbsp;6226&nbsp;1667</span>
        </div>
        <GapView gap="0.82"/>
        <div class="image-text_block flex-row">
          <img class="icon_img" src="./../assets/image/icon_message.png"/>
          <span class="img_text" @click="OnclickEmail">info&#64;petrosunrich.com</span>
        </div>
      </div>
      <GapView gap="2.0"/>
      <FooterItem title="ABOUT US" :is-show-sub="false" @on_click_history="OnclickGrowth" @on_click_company="OnclickAbout" @on_click_team="OnclickTeam"/>
      <FooterItem title="TRADING" :is-show-sub="false" @on_click_crude="OnclickTrading('CrudeOil')" @on_click_fuel="OnclickTrading('FuelOil')" @on_click_lan="OnclickTrading('LNG')" @on_click_products="OnclickTrading('PetroleumProducts')"/>
      <FooterItem title="CAREERS" :is-show-sub="false" @on_click_join="OnclickCareers"/>
      <FooterItem title="CONTACT US" :is-show-sub="false" @on_click_contact="OnclickContact"/>
    </div>
    <div class="text_line flex-col"></div>
    <span class="text_bottom">©2022&nbsp;Petro&nbsp;Sunrich&nbsp;Singapore&nbsp;Pte&nbsp;Ltd&nbsp;copyright</span>
  </div>
</template>

<script setup>

import GapView from "@/components/comment/GapView.vue";
import FooterItem from "@/componentsPhone/comment/FooterItem.vue";
import { useRouter } from 'vue-router';

const router = useRouter();

function OnclickEmail() {
  window.location.href='mailto:info@petrosunrich.com'
}

function OnclickHome() {
  window.location.href = './#/homePage'
}
function OnclickAbout() {
  window.location.href = './#/aboutPage'
}
function OnclickTrading(type) {
  router.push({ name: 'tradingPage', query: { type: type } });
}
function OnclickCareers() {
  window.location.href = './#/careersPage'
}
function OnclickContact() {
  window.location.href = './#/contactPage'
}
function OnclickTeam() {
  window.location.href = './#/teamPage'
}
function OnclickGrowth() {
  window.location.href = './#/historyPage'
}

</script>

<style scoped lang="scss">
.footer_c {
  width: 19.20rem;
  background-color: rgba(53, 56, 65, 1);
  z-index: 2;
}

.footer_block_c {
  .footer_block_1 {
    margin-top: 2.56rem;
    margin-left: 0.82rem;
    .img_c {
      height: 2.05rem;
      cursor: pointer;
      .image_1 {
        width: 1.77rem;
        height: 2.05rem;
        background-size: contain;
      }
      .image_2 {
        width: 4.85rem;
        height: 0.70rem;
        margin-left: 0.61rem;
        background-size: contain;
        background-repeat: no-repeat;
        align-self: center;
      }
    }

    .image-text_block {
      .icon_img {
        width: 0.82rem;
        height: 0.82rem;
        margin-top: 0.46rem;
        background-size: cover;
        //background-color: cyan;

      }

      .img_text_1 {
        overflow-wrap: break-word;
        color: rgba(231, 231, 231, 1);
        font-size: 0.82rem;
        font-family: Montserrat-Regular;
        text-align: left;
        line-height: 1.64rem;
        margin-left: 0.92rem;
      }

      .img_text {
        height: 0.32rem;
        overflow-wrap: break-word;
        color: rgba(231, 231, 231, 1);
        font-size: 0.82rem;
        font-family: Montserrat-Regular;
        text-align: left;
        line-height: 1.64rem;
        margin-left: 0.92rem;
        cursor: pointer;
      }
      .img_text:hover{
        text-decoration: underline;
        text-underline-offset: 0.05rem;
      }
    }
  }
}

.text_line {
  background-color: rgba(216, 216, 216, 0.2);
  width: 100%;
  height: 0.05rem;
  align-self: center;
  margin-top: 1.5rem;
}

.text_bottom {
  width: 100%;
  overflow-wrap: break-word;
  color: #E7E7E7;
  font-size: 0.61rem;
  font-family: Montserrat-Regular;
  text-align: center;
  line-height: 3.23rem;
}
</style>
