<template>
  <div class="slide_block_1 flex-col">
    <div class="box_5 flex-row">
      <div class="text-wrapper_3 flex-col">
        <span class="text_10">{{props.title}}</span>
        <span class="text_11">{{props.name}}</span>
      </div>
    </div>
    <span class="text_12">{{props.desc}}</span>
  </div>

</template>
<script setup>

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  desc: {
    type: String,
    default: ''
  },
  image: {
    type: String,
    default: require('./../../assets/image/team_pic_cfo.png')
  }
})

</script>

<style scoped lang="scss">
.slide_block_1 {
  background-color: rgba(243, 251, 245, 1);
  width: 100%;
  border-radius: 0.05rem;
  padding-right: 0.82rem;
  padding-left: 0.82rem;
  overflow: hidden;

  .box_5 {
    .text-wrapper_3 {
      .text_10 {
        overflow-wrap: break-word;
        color: rgba(89, 89, 89, 1);
        font-size: 0.92rem;
        font-family: Montserrat-Regular;
        text-align: left;
        line-height: 1.13rem;
        margin-top: 1.48rem;
      }

      .text_11 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.23rem;
        font-family: Montserrat-SemiBold;
        text-align: left;
        white-space: nowrap;
        line-height: 1.48rem;
      }
    }
  }
  .text_12 {
    overflow-wrap: break-word;
    color: rgba(89, 89, 89, 1);
    font-size: 0.82rem;
    font-family: Montserrat-Regular;
    text-align: left;
    line-height: 1.23rem;
    margin-top: 0.85rem;
    margin-bottom: 1.48rem;
  }
}


</style>
