import { createStore } from 'vuex'
export default createStore({
  state: {
    slideIsShow: false,
    currentMobiPage: 'homePage'
  },
  getters: {
  },
  mutations: {
    showSlideView: function (state) {
      state.slideIsShow = true
    },
    hideSlideView: function (state) {
      state.slideIsShow = false
    },
    changeCurrentMobiPage: function (state, s) {
      state.currentMobiPage = s
    }
  },
  modules: {
  }
})
