<template>
  <div class="home_flex_block_4 flex-col">
    <div class="home_box_3 flex-col">
      <div class="text-wrapper_4 flex-row">
        <span class="text_17">Our&nbsp;commodities</span>
      </div>
      <span class="text_15">We specialize in sourcing and supplying energy and commodities with a strong commitment to delivering these products responsibly.</span>
      <div class="group_3 flex-row">
        <div class="image-text_1 flex-col">
          <img class="label_1" src="./../../assets/image/icon_fuel.png"/>
          <span class="text-group_1">Crude&nbsp;Oil</span>
        </div>
        <div class="image-text_1 flex-col">
          <img class="label_1" src="./../../assets/image/icon_crude.png"/>
          <span class="text-group_1">Fuel&nbsp;Oil</span>
        </div>
      </div>
      <div class="group_4 flex-row">
        <div class="image-text_1 flex-col">
          <img class="label_1" src="./../../assets/image/icon_lng.png"/>
          <span class="text-group_1">LNG</span>
        </div>
        <div class="image-text_1 flex-col">
          <img class="label_1" src="./../../assets/image/icon_product.png"/>
          <span class="text-group_1">Petroleum&nbsp;Products</span>
        </div>
      </div>
    </div>
    <div class="home_box_4 flex-col"></div>
  </div>

</template>

<script setup>


</script>
<style scoped lang="scss">
.home_flex_block_4 {
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.home_box_3 {
  background-color: #F3FBF5FF;
  position: relative;
  padding-left: 0.82rem;
  padding-right: 0.82rem;
  width: 100%;
  .text-wrapper_4 {
    position: relative;
    width: 100%;
    height: 2.71rem;
    margin-top: 2.05rem;
    background-image: url("./../../assets/image/text_img_stroke_tradding.png");
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    .text_17 {
      overflow-wrap: break-word;
      color: rgba(234, 88, 18, 1);
      font-size: 1.02rem;
      text-transform: uppercase;
      font-family: Montserrat-Black;
      white-space: nowrap;
      align-self: end;
      line-height: 1.23rem;
      margin-top: -0.2rem;
    }
  }
  .text_15 {
    overflow-wrap: break-word;
    color: rgba(71, 71, 71, 1);
    font-size: 0.82rem;
    font-family: Montserrat-Regular;
    text-align: left;
    line-height: 1.23rem;
    margin-top: 1.33rem;
  }

  .group_3 {
    width: 100%;
    margin-top: 2.40rem;
    .image-text_1 {
      width: 7.49rem;
      height: 3.76rem;
      .label_1 {
        width: 1.54rem;
        height: 1.54rem;
        background-size: cover;
      }
      .text-group_1 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 0.82rem;
        font-family: Montserrat-SemiBold;
        text-align: left;
        white-space: nowrap;
        line-height: 0.97rem;
        margin-top: 0.72rem;
      }
    }
  }
  .group_4 {
    width: 100%;
    margin-top: 3.12rem;
    margin-bottom: 3.08rem;
    .image-text_1 {
      width: 7.49rem;
      height: 3.76rem;
      .label_1 {
        width: 1.54rem;
        height: 1.54rem;
      }
      .text-group_1 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 0.82rem;
        font-family: Montserrat-SemiBold;
        text-align: left;
        white-space: nowrap;
        line-height: 0.97rem;
        margin-top: 0.72rem;
      }
    }
  }
}
.home_box_4 {
  width: 100%;
  height: 21.61rem;
  background-image: url("./../../assets/image/home_pic_4.png");
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;

}

</style>
