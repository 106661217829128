<template>
  <div class="slide_btn_c">
    <img class="btn_right_default" v-show="isShowRightNotClick">
    <img class="btn_right" v-show="isShowRightClick" @click="OnClickRight">
    <img class="btn_left_default"  v-show="isShowLeftNotClick">
    <img class="btn_left" v-show="isShowLeftClick" @click="OnClickLeft">
  </div>
</template>

<script setup>
import {defineProps, defineEmits, computed} from 'vue'

const props = defineProps({
  is_right: {
    type: Boolean,
    default: false
  },
  is_last: {
    type: Boolean,
    default: false
  }
})

const isShowRightClick = computed(function () {
  return props.is_right && !props.is_last
})
const isShowRightNotClick = computed(function () {
  return props.is_right & props.is_last
})

const isShowLeftClick = computed(function () {
  return !props.is_right & !props.is_last
})
const isShowLeftNotClick = computed(function () {
  return !props.is_right & props.is_last
})

const emits = defineEmits(['right_btn_click', 'left_btn_click'])

function OnClickRight() {
  console.log("OnClickRight")
  emits("right_btn_click")
}

function OnClickLeft() {
  console.log("OnClickLeft")
  emits("left_btn_click")
}

</script>

<style scoped lang="scss">
.slide_btn_c{
  width: 0.37rem;
  height: 0.37rem;
  position: relative;
  .btn_right {
    background-image: url("./../../assets/image/icon_right_03.png");
  }
  .btn_right:hover {
    background-image: url("./../../assets/image/icon_right_02.png");
  }
  .btn_left {
    background-image: url("./../../assets/image/icon_left_03.png");
  }
  .btn_left:hover {
    background-image: url("./../../assets/image/icon_left_02.png");
  }

  .btn_right_default {
    background-image: url("./../../assets/image/icon_right_01.png");
  }

  .btn_left_default {
    background-image: url("./../../assets/image/icon_left_01.png");
  }

  img {
    width: 0.37rem;
    height: 0.37rem;
    background-size: contain;
    position: absolute;
    cursor: pointer;
    z-index: 100;
    //top: 0;
    //left: 0;
  }
}
</style>
