<template>
  <div class="home_flex_block_4" id="flex_tag_2">
    <transition name="fade">
      <div v-show="isVisibleComputed" class="home_box_3 flex-col">
        <div class="text-wrapper_4 flex-row">
          <span class="text_17">Our&nbsp;commodities</span>
        </div>
        <span class="text_15">We specialize in sourcing and supplying energy and commodities with a strong commitment to delivering these products responsibly.</span>
        <div class="group_3 flex-row justify-between">
          <div class="image-text_1 flex-col justify-between">
            <img class="label_1" src="./../../assets/image/icon_fuel.png"/>
            <span class="text-group_1">Crude&nbsp;Oil</span>
          </div>
          <div class="image-text_1 flex-col justify-between">
            <img class="label_1" src="./../../assets/image/icon_crude.png"/>
            <span class="text-group_1">Fuel&nbsp;Oil</span>
          </div>
        </div>
          <div class="group_4 flex-row justify-between">
            <div class="image-text_1 flex-col justify-between">
              <img class="label_1" src="./../../assets/image/icon_lng.png"/>
              <span class="text-group_1">LNG</span>
            </div>
            <div class="image-text_1 flex-col justify-between">
              <img class="label_1" src="./../../assets/image/icon_product.png"/>
              <span class="text-group_1">Petroleum&nbsp;Products</span>
            </div>
          </div>
      </div>
    </transition>
    <transition name="fade">
    <div v-show="isVisibleComputed" class="home_box_4 flex-col"></div>
    </transition>
  </div>

</template>

<script setup>


import {computed, onMounted, onUnmounted, ref} from "vue";

const isVisible = ref(false);
const isFistShow = ref(true)

const isVisibleComputed = computed(function () {

  if (!isFistShow.value) {
    return true
  }
  return isFistShow.value && isVisible.value

})

onMounted(() => {
  window.addEventListener("scroll", scrollHandle)
});

onUnmounted(() => {
  window.removeEventListener("scroll", scrollHandleRemove)
});

function scrollHandleRemove() {

}

function scrollHandle() {
  const view = document.getElementById('flex_tag_2')
  if (view) {
    const elementTop = view.getBoundingClientRect().top
    const elementBottom = view.getBoundingClientRect().bottom

    if (elementTop <= window.innerHeight && elementBottom >= 0) {
      isVisible.value = true
    } else {
      if (isVisible.value) {
        isFistShow.value = false
      }
      isVisible.value = false
    }
  }
}



</script>
<style scoped lang="scss">
.home_flex_block_4 {
  background-color: rgba(255, 255, 255, 1);
  width: 19.20rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.home_box_3 {
  background-color: #F3FBF5FF;
  position: relative;
  width: 9.60rem;
  height: 10.80rem;
  .text-wrapper_4 {
    position: relative;
    width: 7.15rem;
    height: 1.09rem;
    margin-top: 3.03rem;
    margin-left: 1.21rem;
    background-image: url("./../../assets/image/text_img_stroke_tradding.png");
    background-size: contain;
    .text_17 {
      overflow-wrap: break-word;
      color: rgba(234, 88, 18, 1);
      font-size: 0.36rem;
      text-transform: uppercase;
      font-family: Montserrat-Black;
      text-align: left;
      white-space: nowrap;
      line-height: 0.44rem;
      margin-top: 0.71rem;
    }
  }
  .text_15 {
    overflow-wrap: break-word;
    color: rgba(71, 71, 71, 1);
    font-size: 0.18rem;
    font-family: Montserrat-Regular;
    text-align: left;
    line-height: 0.38rem;
    margin-top: 0.22rem;
    margin-left: 1.21rem;
    animation-delay: 0.5s;
  }

  .group_3 {
    width: 3.28rem;
    height: 0.76rem;
    margin-top: 0.48rem;
    margin-left: 1.20rem;
    animation-delay: 2s;

    .image-text_1 {
      width: 2.49rem;
      height: 0.76rem;
      .label_1 {
        width: 0.36rem;
        height: 0.36rem;
      }
      .text-group_1 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 0.20rem;
        font-family: Montserrat-SemiBold;
        text-align: left;
        white-space: nowrap;
        line-height: 0.24rem;
        margin-top: 0.16rem;
      }
    }
  }

  .group_4 {
    width: 3.28rem;
    height: 0.76rem;
    margin-top: 0.48rem;
    margin-left: 1.20rem;
    animation-delay: 2s;
    .image-text_1 {
      width: 2.49rem;
      height: 0.76rem;
      .label_1 {
        width: 0.36rem;
        height: 0.36rem;
      }
      .text-group_1 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 0.20rem;
        font-family: Montserrat-SemiBold;
        text-align: left;
        white-space: nowrap;
        line-height: 0.24rem;
        margin-top: 0.16rem;
      }
    }
  }
}
.home_box_4 {
  width: 9.60rem;
  height: 10.80rem;
  animation-delay: 0.5s;
  background-image: url("./../../assets/image/home_pic_4.png");
  background-size: cover;
}

.fade-enter-active, .fade-leave-active {
  animation: fadeInUp;
  animation-duration: 2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

</style>
