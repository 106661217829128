<template>
  <div class="page flex-col">
    <PageHeader current_page="about" class="header"/>
    <div class="group_box_1 flex-col">
      <div class="text-wrapper_head flex-row">
        <span class="text-wrapper_head_text">Company Profile</span>
      </div>
    </div>
    <div class="block_2 flex-row">
      <div class="block_3 flex-col">
      </div>
      <div class="text-wrapper_2 flex-col">
        <span class="text_2">about</span>
        <span class="text_3">SUNRICH</span>
        <span class="text_4">Petro Sunrich Singapore Pte Ltd was incorporated in Singapore in 2016 with a primary focus on sourcing and supplying energy and commodities. Since its establishment, the company has successfully sourced and supplied Crude Oil, Fuel Oil, and Petroleum Products. Our team comprises professionals with extensive experience in sourcing, supplying, and related operations. Members of our team come from multinational major trading companies, bringing strong, in-depth knowledge of the Crude Oil, Fuel Oil, and Petroleum Products business.</span>
      </div>
    </div>
    <transition class="fade">
      <div class="block_5 flex-row">
        <div class="list_1 flex-row">
          <div class="text-group_3 flex-col justify-between" v-for="(item, index) in loopData" :key="index">
            <span class="text_10" v-html="item.test1"></span>
            <span class="text_11" v-html="item.test2"></span>
          </div>
        </div>
      </div>
    </transition>
    <div class="block_1 flex-col">
      <div class="text-group_4 flex-col">
        <span class="text_12">Key&nbsp;Strength</span>
        <span class="text_13">CORE&nbsp;COMPETITIVENESS</span>
      </div>
      <div class="block_list flex-row justify-between">
        <div class="block_list_img_text flex-col">
          <img class="image_1" referrerpolicy="no-referrer" src="./../assets/image/about_icon_team.png"/>
          <span class="text_title">Professional&nbsp;Team</span>
          <span class="text_sub">Our team includes seasoned traders and operations experts with nearly 20 years of experience in large state-owned oil companies, trading houses, and finance.</span>
        </div>
        <div class="block_list_img_text flex-col">
          <img class="image_1" referrerpolicy="no-referrer" src="./../assets/image/about_icon_business.png"/>
          <span class="text_title">Business&nbsp;Relations</span>
          <span class="text_sub">Sunrich enjoys long-term relationships with independent refineries and large state-owned oil enterprises in China for many years and is now expanding business into wider areas. </span>
        </div>
        <div class="block_list_img_text flex-col">
          <img class="image_1" referrerpolicy="no-referrer" src="./../assets/image/about_icon_location.png"/>
          <span class="text_title">Location</span>
          <span class="text_sub">Headquartered in Singapore, we capitalize on its excellent geographical location and well-established port and logistics resources to provide strategic advantages to our business.</span>
        </div>
      </div>
    </div>
    <div class="block_6 flex-row">
      <div class="text-wrapper_5 flex-col justify-between">
        <span class="text_20">Our&nbsp;Goal</span>
        <span class="text_21">We are committed to becoming a global energy <br/>and commodity supply chain company.</span>
      </div>
      <span class="text_22">“</span>
    </div>
    <PageFooter/>
  </div>
</template>

<script setup>

import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

const loopData = [
  { test1: '8M+', test2: 'BBLs annual crude oil trade volume' },
  { test1: '1.5M+', test2: 'MT annual fuel oil trade volume' },

  { test1: '15+', test2: 'Years Experience' },
  { test1: '12+', test2: 'Trusted Partners' },
]
</script>

<style scoped lang="scss">
.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  width: 19.20rem;
  overflow: hidden;
  .group_box_1 {
    background-image: url("./../assets/image/adout_head_bg.png");
    width: 100%;
    height: 4.00rem;
    background-size: cover;
    .text-wrapper_head {
      width: 8.74rem;
      height: 1.68rem;
      margin-top: 1.18rem;
      background-image: url("./../assets/image/text_img_stroke_ABOUT2.png");
      background-size: cover;
      position: relative;
      align-self: center;
      .text-wrapper_head_text {
        overflow-wrap: break-word;
        color: rgba(234, 88, 18, 1);
        font-size: 0.90rem;
        text-transform: uppercase;
        font-family: Montserrat-Black;
        text-align: center;
        white-space: nowrap;
        line-height: 1.10rem;
        position: absolute;
        bottom: -0.2rem;
        left: -0.3rem;
      }
    }
  }
  .block_2 {
    width: 100%;
    height: 6.83rem;
    margin-top: 0.13rem;

    .block_3 {
      height: 6.16rem;
      margin-top: 0.67rem;
      width: 5.49rem;
      margin-left: 2.4rem;
      background-image: url("./../assets/image/about_img_1.png");
      background-size: cover;
      .text_title {
        width: 5.49rem;
        height: 3.01rem;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 1.70rem;
        font-family: Montserrat-Black;
        text-align: left;
        white-space: nowrap;
        line-height: 1.58rem;
        margin-top: 3.15rem;
      }
    }

    .text-wrapper_2 {
      width: 8.30rem;
      margin-left: 0.61rem;
      overflow: hidden;
      justify-content: center;


      .text_2 {
        overflow-wrap: break-word;
        color: rgba(89, 89, 89, 1);
        font-size: 0.18rem;
        text-transform: uppercase;
        font-family: Montserrat-Regular;
        text-align: left;
        line-height: 0.65rem;
      }

      .text_3 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 0.56rem;
        font-family: Montserrat-SemiBold;
        text-align: left;
        white-space: nowrap;
        line-height: 0.65rem;
        margin-top: -0.17rem;
      }

      .text_4 {
        overflow-wrap: break-word;
        color: rgba(89, 89, 89, 1);
        font-size: 0.16rem;
        font-family: Montserrat-Regular;
        text-align: left;
        line-height: 0.32rem;
        margin-top: 0.24rem;
      }
    }
  }
  .block_5 {
    background-color: rgba(243, 251, 245, 1);
    width: 100%;
    height: 3.14rem;
    margin-top: 0.80rem;

    .list_1 {
      width: 100%;
      height: 1.31rem;
      padding-left: 1.28rem;
      padding-right: 1.28rem;
      justify-content: space-between;
      align-self: center;
      justify-self: center;
      .text-group_3:nth-child(1) {
        width: 3.64rem;
      }

      .text-group_3:nth-child(2) {
        width: 3.4rem;
      }
      .text-group_3:nth-child(3) {
        width: 2.04rem;
      }
      .text-group_3:nth-child(4) {
        width: 2.08rem;
      }

      .text_10 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 0.64rem;
        font-family: Montserrat-SemiBold;
        white-space: nowrap;
        line-height: 0.78rem;
      }

      .text_11 {
        overflow-wrap: break-word;
        color: rgba(89, 89, 89, 1);
        font-size: 0.24rem;
        font-family: Montserrat-Regular;
        text-align: center;
        white-space: nowrap;
        line-height: 0.29rem;
        margin-top: 0.24rem;
      }

    }

  }
  .block_1 {
    width: 100%;
    .text-group_4 {
      position: relative;
      height: 1.13rem;
      margin-top: 0.8rem;
      .text_12 {
        overflow-wrap: break-word;
        color: rgba(89, 89, 89, 1);
        font-size: 0.18rem;
        text-transform: uppercase;
        font-family: Montserrat-Regular;
        text-align: left;
        line-height: 0.65rem;
        text-align: center;
      }

      .text_13 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 0.56rem;
        font-family: Montserrat-SemiBold;
        text-align: center;
        white-space: nowrap;
        line-height: 0.68rem;
      }
    }
    .block_list {
      width: 100%;
      margin-top: 0.68rem;
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      .block_list_img_text{
        width: 4.0rem;
        .image_1 {
          width: 1.54rem;
          height: 1.55rem;
          background-size: cover;
          align-self: center;
        }
        .text_title{
          overflow-wrap: break-word;
          color: rgba(51, 51, 51, 1);
          font-size: 0.32rem;
          font-family: Montserrat-SemiBold;
          text-align: center;
          white-space: nowrap;
          line-height: 0.39rem;
          margin-top: 0.29rem;
        }
        .text_sub {
          overflow-wrap: break-word;
          color: rgba(89, 89, 89, 1);
          font-size: 0.16rem;
          font-family: Montserrat-Regular;
          text-align: center;
          line-height: 0.32rem;
          margin-top: 0.24rem;
        }
      }

    }
  }
  .block_6 {
    background-image: url("./../assets/image/about_bg_footer.png");
    position: relative;
    width: 100%;
    height: 3.44rem;
    margin-top: 0.80rem;
    .text-wrapper_5 {
      width: 12.86rem;
      height: 1.77rem;
      margin: 0.83rem 0 0 3.17rem;
      position: relative;

      .text_20 {
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 0.32rem;
        text-transform: uppercase;
        font-family: Montserrat-Regular;
        text-align: center;
        white-space: nowrap;
        line-height: 0.39rem;

      }

      .text_21 {
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 0.50rem;
        font-family: Montserrat-BoldItalic;
        text-align: center;
        line-height: 0.61rem;
        margin-top: 0.16rem;
      }
    }
    .text_22 {
      position: absolute;
      left: 3.0rem;
      top: 0.90rem;
      opacity: 0.4;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 135px;
      font-family: Montserrat-ExtraBold;
      text-align: left;
      white-space: nowrap;
    }
  }
}


.animate__animated.animate__bounce {
  --animate-duration: 4s;
}


</style>
