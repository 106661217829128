<template>
  <div class="page flex-col">
    <PageHeader/>
    <div class="block_1 flex-col">
      <div class="text-wrapper_1 flex-col flex-col animate__animated animate__fadeInUp">
        <span class="text_6">OUR&nbsp;GOAL</span>
        <div class="box_3"/>
      </div>
      <span class="text_7 animate__animated animate__fadeInUp">WE ARE COMMITTED TO BECOMING A GLOBAL ENERGY AND COMMODITY SUPPLY CHAIN COMPANY </span>
    </div>
    <HomeFlexContent/>
    <HomeFlexContent2/>
    <HomeBankItem/>
    <PageFooter/>
  </div>
</template>

<script setup>
import PageHeader from "./../componentsPhone/PageHeader.vue";
import PageFooter from "./../componentsPhone/PageFooter.vue";
import HomeFlexContent from "@/componentsPhone/comment/HomeFlexContent.vue";
import HomeBankItem from "@/componentsPhone/comment/HomeBankItem.vue";
import HomeFlexContent2 from "@/componentsPhone/comment/HomeFlexContent2.vue";
import {onMounted} from "vue"
import { useStore } from 'vuex'
const store = useStore()
onMounted(function () {
  store.commit('changeCurrentMobiPage', 'homePage')
})
</script>
<style scoped lang="scss">

.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  width: 19.20rem;
  overflow: hidden;
  overflow-x: hidden;
  .block_1 {
    width: 100%;
    height: 29.44rem;
    background-image: url("./../assets/image/head_home_bg.png");
    background-size: auto 100%;
    background-position: 20%;
    .text-wrapper_1 {
      margin-top: 6.25rem;
      margin-left: 0.82rem;
      .box_3 {
        background-color: rgba(255, 255, 255, 1);
        width: 4.25rem;
        height: 0.20rem;
        margin-top: 0.46rem;
        animation-delay: 0.5s;
      }

      .text_6 {
        //width: 2.21rem;
        //height: 0.49rem;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 1.02rem;
        font-family: Montserrat-Regular;
        text-align: left;
        white-space: nowrap;
        line-height: 1.23rem;
        animation-delay: 0.2s;
      }

    }

    .text_7 {
      position: relative;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 1.64rem;
      font-family: Montserrat-Black;
      text-align: left;
      line-height: 2.05rem;
      margin-left: 0.82rem;
      margin-top: 0.72rem;
      animation-delay: 0.5s;
    }
  }
}

</style>
