
<template>
  <div class="group_email flex-row" @click="OnclickEmail" v-if="props.isHome">
    <div class="image-text_3 flex-row justify-between">
      <span class="text-group_3">Submit your CV</span>
      <img class="thumbnail_1" src="./../../assets/image/icon_back.png"/>
    </div>
  </div>
  <div class="group_email_2 flex-row" @click="OnclickEmail" v-else>
    <div class="image-text_4 flex-row justify-between">
      <span class="text-group_4">Submit your CV</span>
      <img class="thumbnail_2" src="./../../assets/image/icon_back_2.png"/>
    </div>
  </div>
</template>

<script setup>
import {defineProps} from 'vue'

const props = defineProps({
  isHome: {
    type: Boolean,
    default: true
  }
})
function OnclickEmail() {
  window.location.href='mailto:info@petrosunrich.com'
}
</script>

<style scoped lang="scss">
.group_email {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0.41rem;
  width: 10.91rem;
  height: 2.46rem;
  cursor: pointer;

  .image-text_3 {
    //width: 6.86rem;

    .text-group_3 {
      width: 6.86rem;
      color: rgba(9, 10, 11, 1);
      font-size: 0.82rem;
      font-family: Montserrat-SemiBold;
      text-align: left;
      line-height: 2.46rem;
      margin-left: 1.54rem;
      overflow: hidden;
    }

    .thumbnail_1 {
      width: 0.73rem;
      height: 0.51rem;
      margin-left: 0.2rem;
      align-self: center;
      animation: marquee 0.5s ease;
      animation-fill-mode: forwards;
    }

  }
}
//.group_email:hover{
//  .thumbnail_1 {
//    animation: marquee 0.5s ease;
//    animation-fill-mode: forwards;
//  }
//}


.group_email_2 {
  background-color: rgba(234, 88, 18, 1);
  border-radius: 0.41rem;
  width: 10.91rem;
  height: 2.46rem;
  cursor: pointer;

  .image-text_4 {
    .text-group_4 {
      width: 6.86rem;
      color: rgba(255, 255, 255, 1);
      font-size: 0.82rem;
      font-family: Montserrat-SemiBold;
      text-align: left;
      line-height: 2.46rem;
      margin-left: 1.54rem;
      overflow: hidden;
    }
    .thumbnail_2 {
      width: 0.73rem;
      height: 0.51rem;
      margin-left: 0.2rem;
      align-self: center;
      animation: marquee 0.5s ease;
      animation-fill-mode: forwards;
    }
  }
}


@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(0.1rem);
  }
}
</style>
