import {createRouter, createWebHashHistory} from 'vue-router'
import contact from '../views/ContactPage.vue'
import history from '../views/HistoryPage.vue'
import team from '../views/TeamPage.vue'
import about from '../views/AboutPage.vue'
import trading from '../views/TradingPage.vue'
import careers from '../views/CareersPage.vue'
import home from '../views/HomePage.vue'
// mobi
import homePhone from '../viewsPhone/HomePhonePage.vue'
import aboutPhone from '../viewsPhone/AboutPhonePage.vue'
import historyPhone from '../viewsPhone/HistoryPhonePage.vue'
import teamPhone from '../viewsPhone/TeamPhonePage.vue'
import tradingPhone from '../viewsPhone/TradingPhonePage.vue'
import careersPhone from '../viewsPhone/CareersPhonePage.vue'
import contactPhone from '../viewsPhone/ContactPhonePage.vue'



const routes = [
    {
    path: '/',
    redirect: "home",
    component: home
  },
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/team',
    name: 'team',
    component: team
  },
  {
    path: '/history',
    name: 'history',
    component: history
  },
  {
    path: '/trading',
    name: 'trading',
    component: trading
  },
  {
    path: '/careers',
    name: 'careers',
    component: careers
  },

  {
    path: '/homePage',
    name: 'homePage',
    component: homePhone
  },
  {
    path: '/aboutPage',
    name: 'aboutPage',
    component: aboutPhone
  },
  {
    path: '/historyPage',
    name: 'historyPage',
    component: historyPhone
  },
  {
    path: '/teamPage',
    name: 'teamPage',
    component: teamPhone
  },
  {
    path: '/tradingPage',
    name: 'tradingPage',
    component: tradingPhone
  },
  {
    path: '/careersPage',
    name: 'careersPage',
    component: careersPhone
  },
  {
    path: '/contactPage',
    name: 'contactPage',
    component: contactPhone
  },
]


const scrollBehavior = function scrollBehavior () {
  return { top: 0 }
}

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior,
  routes
})

export default router
