<template>
  <div class="team_slide_mobi_c flex-col">
    <div class="team_title_c flex-row justify-between">
      <div class="group_3 flex-col">
        <span class="text_8">TEAM</span>
        <span class="text_9">Meet Our Team</span>
      </div>
      <div class="team_btn_c flex-row justify-between">
        <SlideButtonView :is_last="isShowLastBtn" :is_right="false" @left_btn_click="ShowLastSlide"/>
        <SlideButtonView :is_last="isShowNextBtn" :is_right="true" @right_btn_click="ShowNextSlide"/>
      </div>
    </div>
    <TeamSlideItemView :title="currentData.title" :image="currentData.image" :desc="currentData.desc" :name="currentData.name"/>
  </div>
</template>

<script setup>

import SlideButtonView from "@/componentsPhone/comment/SlideButtonView.vue";
import TeamSlideItemView from "@/componentsPhone/comment/TeamSlideItemView.vue";
import {computed, ref} from "vue";

const datas = [
    {
      "title": "Managing Director",
      "name": "Sam Chen",
      "desc": "Responsible for all company trade and management; holds a Bachelor’s in Management from Wuhan University and an MBA in Marketing from Johnson & Wales University, USA. Enhanced his education with executive courses at Cheung Kong Graduate School of Business and Tsinghua University. Previously worked at BAIC Group in Marketing and Strategic Planning. Later, as Assistant to the President at a large private petrochemical firm, he helped establish the Futures and Spot Trading Department, participated in a significant merger in Dalian, and a major project in Oman's Duqm Economic Zone, showcasing extensive management experience and resource integration skills.",
      "image": require("./../../assets/image/team_pic_ceo.png")
    },
    {
      "title": "Director",
      "name": "Francis Lai",
      "desc": "Holds a Financial MBA and a Master's degree in International Marketing from Leicester, UK, primarily responsible for banking and all finance-related aspects. Before joining the company, he served as a Senior Financial Manager and Chief Financial Officer in large multinational conglomerates' listed companies in Singapore and Hong Kong, covering sectors such as hotels, real estate, shipping, bulk commodity supply chain and trade, as well as the semiconductor industry. He has over 25 years of experience in financial planning, bonds, capital markets, corporate finance, financial management, risk management, policy and regulations, and process implementation. Additionally, he holds a director position at a business consulting firm.",
      "image": require("./../../assets/image/team_pic_cfo.png")
    },
    {
      "title": "Deputy General Manager",
      "name": "Russell Koh",
      "desc": "Deputy General Manager of the company, in charge of oil trading, with over 20 years of professional experience in the oil industry, a senior trader who has worked for international major oil companies and Trading Houses.",
      "image": require("./../../assets/image/team_pic_coo.png")
    }
]

const curryIndex = ref(0)

const isShowLastBtn = computed(function () {
 return (curryIndex.value === 0)
})

const isShowNextBtn = computed(function () {
  return (curryIndex.value === 2)
})

const currentData = computed(function () {
  return datas[curryIndex.value]
})


function ShowNextSlide() {
  if (curryIndex.value  < 2) {
    curryIndex.value = curryIndex.value + 1
  }
}
function ShowLastSlide() {
  console.log("ShowLastSlide: current: ", curryIndex.value)
  if (curryIndex.value  > 0) {
    curryIndex.value = curryIndex.value - 1
  }
}

</script>

<style scoped lang="scss">
.team_slide_mobi_c {
  width: 19.20rem;
  position: relative;
  padding-left: 0.82rem;
  padding-right: 0.82rem;
  .team_title_c{
    width: 100%;
    height: 5.63rem;
    .group_3 {
      height: 100%;
      .text_8 {
        overflow-wrap: break-word;
        color: rgba(89, 89, 89, 1);
        font-size: 0.92rem;
        font-family: Montserrat-Regular;
        text-align: left;
        line-height: 1.13rem;
        margin-top: 1.64rem;

      }
      .text_9 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.43rem;
        font-family: Montserrat-SemiBold;
        text-align: left;
        white-space: nowrap;
        line-height: 1.74rem;
        margin-top: 0.26rem;
      }

    }
    .team_btn_c{
      width: 4.65rem;
      height: 1.89rem;
      margin-top: 2.51rem;
      position: relative;
    }
  }

}
</style>
