<template>
<div class="footer_item_c flex-col">
  <div class="title_c flex-row justify-between" @click="OnClickTitle">
    <span class="title">{{ props.title }}</span>
    <img src="../../assets/image/foot_list_up.png" v-if="isShowSubtitle">
    <img src="../../assets/image/foot_list_down.png" v-else>
  </div>
  <div class="subtitle_c flex-col" v-show="isShowSubtitle">
    <div class="line"/>
    <span v-for="item in subList" :key="item" class="subtitle" @click="OnClickItem(item)">
      {{ item }}
    </span>
  </div>
</div>
</template>

<script setup>

import {computed, defineEmits, defineProps, ref} from "vue";



const list = {
  "about": ["Company Profile", "History", "Our Team"],
  "trading": ["Crude Oil", "LNG", "Fuel Oil", "Petroleum Products"],
  "careers": ["Join Us"],
  "contact": ["Contact Details"]
}


const subList = computed(function () {
  if (props.title === "ABOUT US") {
    return list['about']
  } else if (props.title === "TRADING") {
    return list['trading']
  } else if (props.title === "CAREERS") {
    return list['careers']
  } else {
    return list['contact']
  }

})


const props = defineProps({
  title: {
    type: String,
    default: 'ABOUT US'
  },
  isShowSub: {
    type: Boolean,
    default: false
  },
})

const showSubRef = ref(props.isShowSub)

const isShowSubtitle = computed(function () {
  return showSubRef.value
})

function OnClickTitle() {
  showSubRef.value = !showSubRef.value
}

const emits = defineEmits(['on_click_history', 'on_click_team', 'on_click_company', 'on_click_crude', 'on_click_fuel', 'on_click_lan', 'on_click_products', 'on_click_join', 'on_click_contact'])

function OnClickItem(item) {
  if (item === 'Company Profile') {
    emits("on_click_company")
  } else if (item === 'History') {
    emits("on_click_history")
  } else if (item === 'Our Team') {
    emits("on_click_team")
  } else if (item === 'Crude Oil') {
    emits("on_click_crude")
  } else if (item === 'Fuel Oil') {
    emits("on_click_fuel")
  } else if (item === 'LNG') {
    emits("on_click_lan")
  } else if (item === 'Petroleum Products') {
    emits("on_click_products")
  } else if (item === 'Join Us') {
    emits("on_click_join")
  } else if (item === 'Contact Details') {
    emits("on_click_contact")
  } else {
    emits("on_click_history")
  }
}

</script>

<style scoped lang="scss">
.footer_item_c {
  .title_c {
    width: 100%;
    height: 3.40rem;
    padding-left: 0.82rem;
    padding-right: 0.82rem;
    cursor: pointer;
    .title {
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 0.82rem;
      font-family: Montserrat-Bold;
      font-weight: 700;
      white-space: nowrap;
      line-height: 0.97rem;
      align-self: center;
    }
    img {
      width: 1.01rem;
      height: 1.01rem;
      background-size: contain;
      align-self: center;
    }
  }
  .subtitle_c {
    width: 100%;
    padding-left: 0.82rem;
    padding-right: 0.82rem;
    padding-bottom: 0.82rem;
    .line {
      width: 100%;
      height: 0.05rem;
      margin-bottom: 0.82rem;
      background: rgba(216, 216, 216, 0.2);
    }
    .subtitle {
      overflow-wrap: break-word;
      color: rgba(231, 231, 231, 1);
      font-size: 0.92rem;
      font-family: Montserrat-Regular;
      text-align: left;
      white-space: nowrap;
      line-height: 2.46rem;
      cursor: pointer;
    }

    //.subtitle:hover{
    //  text-decoration: underline;
    //  text-underline-offset: 0.05rem;
    //}
  }
}

</style>
