<template>
<div class="slide_item_c flex-col">
  <div class="title_c flex-row justify-between" @click="OnClickTitle">
    <span :class="isCurrent? 'title': 'title_2'">{{ props.title }}</span>
    <img src="../../assets/image/foot_list_up_1.png" v-if="isShowSubtitle">
    <img src="../../assets/image/foot_list_down_2.png" v-else>
  </div>
  <div class="subtitle_c flex-col" v-show="isShowSubtitle">
    <GapView gap="0.4"/>
    <span :class="isAboutPage? 'subtitle': 'subtitle_2'" @click="OnClickCompany">Company Profile</span>
    <span :class="isHistoryPage? 'subtitle': 'subtitle_2'" @click="OnClickHistory">History</span>
    <span :class="isTeamPage? 'subtitle': 'subtitle_2'" @click="OnClickTeam">Our Team</span>
    <GapView gap="0.4"/>
  </div>
</div>
</template>

<script setup>

import {computed, defineEmits, defineProps, ref} from "vue";
import GapView from "@/components/comment/GapView.vue";


const props = defineProps({
  title: {
    type: String,
    default: 'ABOUT US'
  },
  isShowSub: {
    type: Boolean,
    default: false
  },
  isAboutPage: {
    type: Boolean,
    default: false
  },
  isTeamPage: {
    type: Boolean,
    default: false
  },
  isHistoryPage: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['on_click_history', 'on_click_team', 'on_click_company'])


const showSubRef = ref(props.isAboutPage || props.isTeamPage || props.isHistoryPage)

const isShowSubtitle = computed(function () {
  return showSubRef.value
})

function OnClickTitle() {
  showSubRef.value = !showSubRef.value
}

function OnClickHistory() {
  emits('on_click_history')
}
function OnClickTeam() {
  emits('on_click_team')
}
function OnClickCompany() {
  emits('on_click_company')
}


const isAboutPage = computed(function () {
  return props.isAboutPage
})
const isTeamPage = computed(function () {
  return props.isTeamPage
})
const isHistoryPage = computed(function () {
  return props.isHistoryPage
})

const isCurrent = computed(function () {
  return props.isAboutPage || props.isTeamPage || props.isHistoryPage
})

</script>

<style scoped lang="scss">
.slide_item_c {
  .title_c {
    width: 100%;
    height: 2.40rem;
    cursor: pointer;
    .title_2 {
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 0.82rem;
      font-family: Montserrat-Regular;
      align-self: center;
    }
    .title {
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 0.82rem;
      font-family: Montserrat-Bold;
      align-self: center;
    }
    img {
      width: 1.01rem;
      height: 1.01rem;
      background-size: contain;
      align-self: center;
    }
  }
  .subtitle_c {
    width: 100%;
    background: #F4F2F2;
    border-radius: 0.10rem;
    .subtitle_2 {
      overflow-wrap: break-word;
      color: rgba(119, 116, 120, 1);
      font-size: 0.92rem;
      font-family: Montserrat-Regular;
      text-align: left;
      white-space: nowrap;
      line-height: 2.46rem;
      margin-left: 0.82rem;
      cursor: pointer;
    }
    .subtitle {
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 0.92rem;
      font-family: Montserrat-Bold;
      text-align: left;
      white-space: nowrap;
      line-height: 2.46rem;
      margin-left: 0.82rem;
      cursor: pointer;
    }
  }
}

</style>
