<template>
  <div class="slide_btn_c">
    <div class="text-wrapper_4 flex-col" v-if="btnClick"><span class="text_10">{{props.name}}</span></div>
    <div class="text-wrapper_5 flex-col" v-else><span class="text_11">{{props.name}}</span></div>
  </div>
</template>
<script setup>

import {defineProps, computed} from 'vue'

const props = defineProps({
  name: {
    type: String,
    default: ''
  },
  isClick: {
    type: Boolean,
    default: false
  }
})

const btnClick = computed(function () {
  return props.isClick
})

</script>

<style scoped lang="scss">
.slide_btn_c {
  height: 1.43rem;
  position: relative;
  .text-wrapper_4 {
    height: 1.43rem;
    background-color: rgba(232, 100, 37, 1);
    border-radius: 0.75rem;
    .text_10 {
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 0.82rem;
      font-family: Montserrat-SemiBold;
      text-align: center;
      line-height: 1.43rem;
      margin-left: 0.82rem;
      margin-right: 0.82rem;

    }
  }
  .text-wrapper_5 {
    height: 1.43rem;
    border-radius: 0.75rem;
    border: 1px solid rgba(234, 88, 18, 1);
    cursor: pointer;
    .text_11 {
      overflow-wrap: break-word;
      color: rgba(232, 100, 37, 1);
      font-size: 0.82rem;
      font-family: Montserrat-SemiBold;
      text-align: center;
      line-height: 1.43rem;
      margin-left: 0.82rem;
      margin-right: 0.82rem;
    }
  }
  .text-wrapper_5:hover{
    background-color: rgba(232, 100, 37, 0.10);
  }
}
</style>
