<template>
  <div class="page flex-col">
    <PageHeader/>
    <div class="group_box_1 flex-col">
      <div class="text-wrapper_head flex-row">
        <span class="text-wrapper_head_text">CAREERS</span>
      </div>
    </div>
    <div class="group_1 flex-col">
      <span class="text_8">CAREERS</span>
      <span class="text_9">WORK ALONGSIDE <br/>AMAZING PEOPLE</span>
      <span class="text_10">Interested in working for Sunrich? We offer opportunities for a diverse <br/>range of dedicated, driven people.      </span>
      <ArrowAnimView :is-home="false" class="arrow_c"/>
    </div>
    <div class="box_4 flex-col">
      <ScroolPicWllView :is-reversed="false"/>
      <ScroolPicWllView :is-reversed="true"/>
    </div>
    <PageFooter/>
  </div>
</template>

<script setup>

import PageFooter from "@/componentsPhone/PageFooter.vue";
import PageHeader from "@/componentsPhone/PageHeader.vue";
import ScroolPicWllView from "@/componentsPhone/comment/ScroolPicWllView.vue";
import ArrowAnimView from "@/componentsPhone/comment/ArrowAnimView.vue";

import {onMounted} from "vue"
import { useStore } from 'vuex'
const store = useStore()
onMounted(function () {
  store.commit('changeCurrentMobiPage', 'careersPage')
})
</script>

<style scoped lang="scss">
.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  width: 19.20rem;
  overflow: hidden;

  .group_box_1 {
    background-image: url("./../assets/image/adout_head_bg.png");
    width: 100%;
    height: 10.32rem;
    background-size: auto 100%;
    background-position: center;
    .text-wrapper_head {
      width: 17.16rem;
      height: 2.56rem;
      margin-top: 5.32rem;
      background-image: url("./../assets/image/text_img_stroke_careers.png");
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      align-self: center;
      .text-wrapper_head_text {
        overflow-wrap: break-word;
        color: rgba(234, 88, 18, 1);
        font-size: 1.69rem;
        text-transform: uppercase;
        font-family: Montserrat-Black;
        text-align: center;
        white-space: nowrap;
        line-height: 2.05rem;
        position: absolute;
        bottom: -0.4rem;
        width: 100%;
      }
    }
  }

  .group_1 {
    width: 100%;
    padding-left: 0.82rem;
    padding-right: 0.82rem;
    position: relative;

    .text_8 {
      overflow-wrap: break-word;
      color: rgba(89, 89, 89, 1);
      font-size: 0.92rem;
      font-family: Montserrat-Regular;
      text-align: left;
      line-height: 1.13rem;
      margin-top: 1.43rem;
    }

    .text_9 {
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 1.43rem;
      font-family: Montserrat-SemiBold;
      text-align: left;
      line-height: 1.74rem;
      margin-top: 0.41rem;
    }

    .text_10 {
      overflow-wrap: break-word;
      color: rgba(89, 89, 89, 1);
      font-size: 0.82rem;
      font-family: Montserrat-Regular;
      text-align: left;
      line-height: 1.23rem;
      margin-top: 1.23rem;
    }
    .arrow_c {
      position: relative;
      width: 10.91rem;
      height: 2.46rem;
      margin-top: 1.43rem;
    }
    .image_3 {
      width: 5.00rem;
      height: 5.78rem;
      position: absolute;
      right: -1rem;
      top: 0;
    }
    .group_2 {
      position: relative;
      width: 2.13rem;
      height: 0.52rem;
    }

  }

  .box_4 {
    position: relative;
    width: 100%;
    margin-top: 2.46rem;
    margin-bottom: 2.46rem;
  }
}

</style>
