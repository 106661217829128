<template>
  <div class="group_1 flex-col">
    <span :class="isCurrent? 'text_1_bold': 'text_1'" :style="{ width:widthComputed, color:textComputed}">{{title}}</span>
    <div class="block_2 flex-col" :style="{backgroundColor:textComputed}" v-show="isCurrent"></div>
  </div>
</template>

<script setup>

import {computed, defineProps, ref} from 'vue'
const props = defineProps({
  isTop: {
    type: Boolean,
    default: false
  },
  is_selected: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  width: {
    type: Number,
    default: 0
  }
})

const isMouseOver = ref(false)

const isCurrent = computed(function () {
  if (props.is_selected) {
    return true
  } else if (isMouseOver.value) {
    return true
  } else {
    return false
  }

})

const widthComputed = computed(function () {
  return props.width + 'rem'
})

const textComputed = computed(function () {

  if (props.isTop) {
    return 'white'
  } else {
    return 'black'
  }

})

</script>


<style scoped lang="scss">
.group_1 {
  height: 0.25rem;
  cursor: pointer;
  align-self: center;
  .text_1 {
    overflow-wrap: break-word;
    //color: rgba(255, 255, 255, 1);
    font-size: 0.18rem;
    line-height: 0.22rem;
    font-family: Montserrat-Regular;
    text-align: left;
    white-space: nowrap;
  }
  .text_1_bold {
    overflow-wrap: break-word;
    //color: rgba(255, 255, 255, 1);
    font-size: 0.18rem;
    line-height: 0.22rem;
    font-family: Montserrat-SemiBold;
    text-align: left;
    white-space: nowrap;
  }
  .block_2 {
    //background-color: rgba(255, 255, 255, 1);
    width: 0.27rem;
    height: 0.02rem;
    margin-top: 0.03rem;
  }

}

</style>
