<template>
  <div class="footer_c flex-col">
    <div class="footer_block_c flex-row">
      <div class="footer_block_1 flex-col">
        <div class="img_c flex-row" @click="OnclickHome">
          <img class="image_1" src="./../assets/image/icon.png"/>
          <img class="image_2" src="./../assets/image/icon_title.png"/>
        </div>
        <div class="image-text_block flex-row">
          <img class="icon_img" src="./../assets/image/icon_location.png"/>
          <span class="img_text_1">6&nbsp;Shenton&nbsp;Way,&nbsp;OUE&nbsp;Downtown&nbsp;2&nbsp;#20-11,<br/>&nbsp;Singapore&nbsp;068809</span>
        </div>
        <GapView gap="0.32"/>
        <div class="image-text_block flex-row">
          <img class="icon_img" src="./../assets/image/icon_phone.png"/>
          <span class="img_text_1">+65&nbsp;6226&nbsp;1666</span>
        </div>
        <div class="image-text_block flex-row">
          <img class="icon_img" src="./../assets/image/icon_print.png"/>
          <span class="img_text_1">+65&nbsp;6226&nbsp;1667</span>
        </div>
        <div class="image-text_block flex-row">
          <img class="icon_img" src="./../assets/image/icon_message.png"/>
          <span class="img_text" @click="OnclickEmail">info&#64;petrosunrich.com</span>
        </div>
      </div>
      <div class="footer_block_2 flex-col">
        <span class="text_1">ABOUT&nbsp;US</span>
        <span class="text_2" @click="OnclickAbout">Company&nbsp;Profile</span>
        <span class="text_2" @click="OnclickGrowth">History</span>
        <span class="text_2" @click="OnclickTeam">Our&nbsp;Team</span>
      </div>
      <div class="footer_block_2 flex-col">
        <span class="text_1">TRADING</span>
        <span class="text_2" @click="OnclickTrading('CrudeOil')">Crude&nbsp;Oil</span>
        <span class="text_2" @click="OnclickTrading('FuelOil')">Fuel&nbsp;Oil</span>
        <span class="text_2" @click="OnclickTrading('LNG')">LNG</span>
        <span class="text_2" @click="OnclickTrading('PetroleumProducts')">Petroleum&nbsp;Products</span>
      </div>

      <div class="footer_block_2 flex-col">
        <span class="text_1">CAREERS</span>
        <span class="text_2"  @click="OnclickCareers">Join&nbsp;Us</span>
      </div>
      <div class="footer_block_2 flex-col">
        <span class="text_1">CONTACT&nbsp;US</span>
        <span class="text_2" @click="OnclickContact">Contact&nbsp;Details</span>
      </div>

    </div>
    <div class="text_line flex-col"></div>
    <span class="text_bottom">©2022&nbsp;Petro&nbsp;Sunrich&nbsp;Singapore&nbsp;Pte&nbsp;Ltd&nbsp;copyright</span>
  </div>
</template>

<script setup>

import GapView from "@/components/comment/GapView.vue";
import { useRouter } from 'vue-router';

const router = useRouter();


function OnclickHome() {
  window.location.href = './#/home'
}
function OnclickAbout() {
  window.location.href = './#/about'
}

function OnclickEmail() {
  window.location.href='mailto:info@petrosunrich.com'
}

function OnclickTrading(type) {
  router.push({ name: 'trading', query: { type: type } });
  // console.log("aaaa", id)
  // window.location.href = './#/trading?id=' + id
}
function OnclickCareers() {
  window.location.href = './#/careers'
}
function OnclickContact() {
  window.location.href = './#/contact'
}
function OnclickTeam() {
  window.location.href = './#/team'
}

function OnclickGrowth() {
  window.location.href = './#/history'

}

</script>

<style scoped lang="scss">
.footer_c {
  width: 19.20rem;
  height: 5.30rem;
  background-color: #2c3e50;
  z-index: 2;
}

.footer_block_c {
  margin-top: 0.75rem;

  .footer_block_1 {
    width: 5.7rem;
    margin-left: 2.38rem;
    .img_c {
      height: 0.73rem;
      width: 2.6rem;
      cursor: pointer;
      .image_1 {
        width: 0.63rem;
        height: 0.73rem;
        background-size: cover;
      }

      .image_2 {
        width: 1.73rem;
        height: 0.25rem;
        margin-left: 0.22rem;
        margin-top: 0.32rem;
        background-size: cover;
      }
    }

    .image-text_block {
      margin-top: 0.16rem;

      .icon_img {
        width: 0.16rem;
        height: 0.16rem;
        margin-top: 0.08rem;
        background-size: cover;
        //background-color: cyan;

      }

      .img_text_1 {
        height: 0.32rem;
        overflow-wrap: break-word;
        color: rgba(231, 231, 231, 1);
        font-size: 0.16rem;
        font-family: Montserrat-Regular;
        text-align: left;
        line-height: 0.32rem;
        margin-left: 0.22rem;
      }

      .img_text {
        height: 0.32rem;
        overflow-wrap: break-word;
        color: rgba(231, 231, 231, 1);
        font-size: 0.16rem;
        font-family: Montserrat-Regular;
        text-align: left;
        line-height: 0.32rem;
        margin-left: 0.22rem;
        cursor: pointer;
      }
      .img_text:hover{
        text-decoration: underline;
        text-underline-offset: 0.05rem;
      }
    }
  }

  .footer_block_2 {
    width: 2.51rem;

    .text_1 {
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 0.20rem;
      font-family: Montserrat-Bold;
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
      line-height: 0.72rem;
    }

    .text_2 {
      overflow-wrap: break-word;
      color: rgba(231, 231, 231, 1);
      font-size: 0.18rem;
      font-family: Montserrat-Regular;
      text-align: left;
      white-space: nowrap;
      line-height: 0.32rem;
      margin-top: 0.16rem;
      cursor: pointer;
    }

    .text_2:hover{
      text-decoration: underline;
      text-underline-offset: 0.05rem;
    }
  }
}

.text_line {
  background-color: rgba(216, 216, 216, 0.2);
  width: 14.40rem;
  height: 1px;
  align-self: center;
  margin-top: 0.56rem;
}

.text_bottom {
  width: 100%;
  overflow-wrap: break-word;
  color: rgba(231, 231, 231, 1);
  font-size: 0.14rem;
  font-family: Montserrat-Regular;
  text-align: center;
  line-height: 0.32rem;
  margin-top: 0.31rem;
}
</style>
