<template>
  <div>
    <router-view/>
    <SlidingView/>
  </div>
</template>
<script setup>
import {onMounted, onUnmounted, ref, watch} from "vue";
import SlidingView from "@/componentsPhone/SlidingView.vue";
import {useRoute, useRouter} from "vue-router";

onMounted(function () {
  const route = useRoute()
  const router = useRouter()
  const mobiFlag = isMobi()
  const ratio = document.documentElement.clientWidth / 1920
  // const ratio2 = document.documentElement.clientWidth / 1080
  const body = document.documentElement
  body.style.fontSize = `${(ratio) * 100}px`

  if (route.path === '/' || route.path === '/home' || route.path === '/homePage') {
    if (mobiFlag) {
      router.replace('/homePage')
    } else {
      router.replace('/')
    }
  }
})

const mobiClientWith = ref(document.documentElement.clientWidth)
const handleResize = () => {
  mobiClientWith.value = document.documentElement.clientWidth
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})

watch(mobiClientWith, (newWith, oldWith) => {
  console.log(newWith, oldWith)

  const body = document.documentElement
  const ratio = document.documentElement.clientWidth / 1920
  body.style.fontSize = `${(ratio) * 100}px`
})

function isMobi () {
  // console.log('UserAgent:', navigator.userAgent)
  // let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  // return flag;
  const width = document.documentElement.clientWidth
  const height = document.documentElement.clientHeight
  if (width > height) {
    return false
  }
  return true
}

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<!--<style lang="css">-->
<!--//#app {-->
<!--//  font-family: 'sans-serif';-->
<!--//  -webkit-font-smoothing: antialiased;-->
<!--//  -moz-osx-font-smoothing: grayscale;-->
<!--//  text-align: center;-->
<!--//  color: #2c3e50;-->
<!--//}-->



<!--</style>-->
