<template>
  <div class="page flex-col">
    <PageHeader/>
    <div class="group_box_1 flex-col">
      <div class="text-wrapper_head flex-col">
        <div class="text-wrapper_head_img"/>
        <span class="text-wrapper_head_text">Our Growth</span>
      </div>
    </div>
    <div class="box_2 flex-col">
      <div class="text-wrapper_3 flex-col animate__animated animate__fadeInUp">
        <span class="text_8">HISTORY</span>
        <span class="text_9">Company's&nbsp;Milestones</span>
      </div>
      <span class="list_text_title animate__animated animate__fadeInUp animate__delay-1s">2018</span>
      <div class="section_list_1 flex-row justify-between animate__animated animate__fadeInUp animate__delay-1s">
        <div class="group_elips flex-col"></div>
        <div class="text-wrapper_right flex-col">
          <img class="group_2 flex-col" src="./../assets/image/history_img_1.png">
          <span class="text_11">Unlocking&nbsp;Global&nbsp;Markets</span>
          <span class="text_12">The company initiated assistance to independent refineries in China by providing overseas crude oil procurement services, aiding customers in finding suitable crude oil varieties abroad. </span>
        </div>
      </div>
      <span class="list_text_title">2021</span>
      <div class="section_list_1 flex-row justify-between">
        <div class="group_elips flex-col"></div>
        <div class="text-wrapper_right flex-col">
          <img class="group_2 flex-col" src="./../assets/image/history_img_2.png">
          <span class="text_11">Expanding&nbsp;Trade&nbsp;Horizons</span>
          <span class="text_12">The scope of trade expanded from sole crude oil transactions to encompass fuel oil and other related petrochemical products.
          </span>
        </div>
      </div>

      <span class="list_text_title">2022</span>
      <div class="section_list_1 flex-row justify-between">
        <div class="group_elips flex-col"></div>
        <div class="text-wrapper_right flex-col">
          <img class="group_2 flex-col" src="./../assets/image/history_img_3.png">
          <span class="text_11">Building&nbsp;Relationships&nbsp;with&nbsp;<br/>Industry&nbsp;Leaders</span>
          <span class="text_12">The company's primary customers and suppliers include China's independent refineries, national oil companies, and large trading houses. Currently, the average annual trade volume of crude oil is approximately 8 million barrels, and the average annual trade volume of fuel oil is around 1.5M tons.</span>
        </div>
      </div>

      <span class="list_text_title">Future</span>
      <div class="section_list_1 flex-row justify-between">
        <div class="group_elips flex-col"></div>
        <div class="text-wrapper_right flex-col">
          <img class="group_2 flex-col" src="./../assets/image/history_img_4.png">
          <span class="text_11">Strategic&nbsp;Expansion</span>
          <span class="text_12">The company plans to actively develop upstream and downstream resources while expanding the scope and breadth of its business. It aims to extend its reach throughout Southeast Asia with Singapore as the hub and engage in global energy and bulk commodity trade.</span>
        </div>
      </div>
    </div>
    <PageFooter class="footer"/>
  </div>
</template>

<script setup>

import PageHeader from "@/componentsPhone/PageHeader.vue";
import PageFooter from "@/componentsPhone/PageFooter.vue";
import {onMounted} from "vue"
import { useStore } from 'vuex'
const store = useStore()
onMounted(function () {
  store.commit('changeCurrentMobiPage', 'historyPage')
})
</script>

<style scoped lang="scss">
.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  width: 19.20rem;
  overflow: hidden;
  .group_box_1 {
    background-image: url("./../assets/image/adout_head_bg.png");
    width: 100%;
    height: 10.34rem;
    background-size: auto 100%;
    background-position: center;
    .text-wrapper_head {
      width: 100%;
      height: 2.56rem;
      margin-top: 5.32rem;
      background-size: cover;
      position: relative;
      .text-wrapper_head_img {
        width: 16.23rem;
        height: 2.56rem;
        align-self: center;
        background-image: url("./../assets/image/text_img_stroke_HISTORY.png");
        background-size: auto 100%;
      }
      .text-wrapper_head_text {
        overflow-wrap: break-word;
        color: rgba(234, 88, 18, 1);
        font-size: 1.69rem;
        text-transform: uppercase;
        font-family: Montserrat-Black;
        white-space: nowrap;
        line-height: 2.05rem;
        position: absolute;
        align-self: center;
        bottom: -0.4rem;
      }
    }
  }
  .box_2{
    width: 100%;
    .text-wrapper_3 {
      position: relative;
      width: 100%;
      margin-top: 1.42rem;
      align-self: center;
      .text_8 {
        overflow-wrap: break-word;
        color: rgba(89, 89, 89, 1);
        font-size: 0.92rem;
        font-family: Montserrat-Regular;
        line-height: 1.13rem;
      }
      .text_9 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.13rem;
        font-family: Montserrat-SemiBold;
        text-align: center;
        white-space: nowrap;
        line-height: 1.74rem;
        margin-top: 0.41rem;
      }
    }
    .list_text_title{
      overflow-wrap: break-word;
      color: rgba(234, 88, 18, 1);
      font-size: 1.43rem;
      margin-left: 0.36rem;
      font-family: Montserrat-SemiBold;
      text-align: left;
      white-space: nowrap;
      line-height: 1.74rem;
      margin-top: 2rem;
    }
    .section_list_1{
      width: 100%;
      margin-top: 0.92rem;
      padding-left: 1.45rem;
      padding-right: 0.82rem;
      .group_elips {
        width: 0.36rem;
        height: 20.58rem;
        align-self: center;
        background-image: url("./../assets/image/history_item_line_2.png");
        background-size: contain;
        background-repeat: no-repeat;
      }
      .text-wrapper_right {
        width: 14.34rem;
        align-self: center;
        .group_2 {
          //border-radius: 1.69rem 0.31rem 1.69rem 0.31rem;
          width: 100%;
          height: 11.16rem;
        }
        .text_11 {
          overflow-wrap: break-word;
          color: rgba(51, 51, 51, 1);
          font-size: 0.92rem;
          font-family: Montserrat-SemiBold;
          text-align: left;
          white-space: nowrap;
          line-height: 1.13rem;
          margin-top: 1.64rem;
        }
        .text_12 {
          overflow-wrap: break-word;
          color: rgba(89, 89, 89, 1);
          font-size: 0.82rem;
          font-family: Montserrat-Regular;
          text-align: left;
          line-height: 1.23rem;
          margin-top: 0.82rem;
        }
      }
      .text-wrapper_left{
        width: 5.86rem;
        align-self: center;
        .text_11 {
          overflow-wrap: break-word;
          color: rgba(51, 51, 51, 1);
          font-size: 0.32rem;
          font-family: Montserrat-SemiBold;
          text-align: right;
          white-space: nowrap;
          line-height: 0.34rem;
        }
        .text_12 {
          overflow-wrap: break-word;
          color: rgba(89, 89, 89, 1);
          font-size: 0.16rem;
          font-family: Montserrat-Regular;
          text-align: right;
          line-height: 0.32rem;
          margin-top: 0.24rem;
        }
      }
    }
  }
}
.footer{
  margin-top: 1.0rem;
}

</style>
