<template>
  <transition name="fade">
    <div class="header_box_1 flex-row justify-between" :style="currentHeaderStyle" v-show="isNavbarVisible">
      <div class="box_left flex-row justify-between" @click="OnclickHome">
        <img class="logo_img_1" src="../assets/image/icon.png"/>
        <img class="logo_img_2" :src="headImgStyle"/>
      </div>
      <div class="box_right" @click="OnClickMeau">
        <img class="logo_meau" :src="headMeauStyle"/>
      </div>
    </div>
  </transition>
</template>

<script setup>

import {computed, onMounted, onUnmounted, ref} from 'vue'
import { useStore } from 'vuex'
const store = useStore()
function OnclickHome() {
  window.location.href = './#/homePage'
}

function OnClickMeau() {
  store.commit('showSlideView')
}

const isNavbarAtTop = ref(true);
const navbarHeight = 20;

const topValue = ref(0)

const isNavbarVisible = ref(true)

const handleScroll = () => {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  isNavbarAtTop.value = scrollTop <= navbarHeight;
  if (isNavbarAtTop.value) {
    style.value = styleObject1
  } else {
    style.value = styleObject
  }
  if (scrollTop > topValue.value) {
    isNavbarVisible.value = false
  } else {
    isNavbarVisible.value = true
  }
  topValue.value = scrollTop
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

const styleObject ={
  backgroundColor: 'white',
  boxShadow: '0px 0px 0.5rem 0px rgba(0, 0, 0, 0.2)'
}

const styleObject1 = {
  backgroundColor: 'transparent',
}

const style = ref({})

const currentHeaderStyle = computed(function () {
  return style.value
})

// const isTop = computed(function () {
//   return isNavbarAtTop.value
// })

const headImgStyle = computed(function () {
  if (isNavbarAtTop.value) {
    return require('./../assets/image/icon_title.png')
  } else {
    return require('./../assets/image/logo_img_3.png')

  }
})

const headMeauStyle = computed(function () {
  if (isNavbarAtTop.value) {
    return require('./../assets/image/icon_meau.png')
  } else {
    return require('./../assets/image/icon_meau_1.png')
  }
})

</script>

<style scoped lang="scss">
.header_box_1 {
  width: 100%;
  height: 4.1rem;
  padding-left: 0.82rem;
  padding-right: 0.82rem;
  position: fixed;
  z-index: 100;
}
.box_left {
  width: 7.25rem;
  height: 2.05rem;
  align-self: center;
  cursor: pointer;
  .logo_img_1 {
    width: 1.77rem;
    height: 2.05rem;
    align-self: center;
  }
  .logo_img_2 {
    width: 4.84rem;
    height: 0.71rem;
    margin-left: 0.61rem;
    align-self: center;
  }
}

.box_right{
  width: 1.13rem;
  height: 1.13rem;
  align-self: center;
  cursor: pointer;
  .logo_meau{
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all 1s ease;
}
.fade-enter, .fade-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

</style>
