<template>
  <div class="page flex-col">
    <PageHeader/>
    <div class="group_box_1 flex-col">
      <div class="text-wrapper_head flex-col">
        <div class="text-wrapper_head_img"/>
        <span class="text-wrapper_head_text">Meet Our Team</span>
      </div>
    </div>
    <div class="text-group_1 flex-col">
      <span class="text_8">CONTACT&nbsp;US</span>
      <span class="text_9">LET’S&nbsp;TALK</span>
      <span class="text_10">Got a question? Want to learn more about <br/>us? Get in touch.</span>
    </div>
    <div class="list_1 flex-col">
      <div class="list-items_1 flex-col" v-for="(item, index) in loopData0" :key="index">
        <img class="image_3" :src="item.image"/>
        <span class="text_11" v-html="item.title"></span>
        <span class="text_12" v-html="item.desc"></span>
        <GapView gap="2.05"/>
      </div>
    </div>

    <div class="group_3 flex-col">
<!--      <GoogleMapView/>-->
      <div class="box_3 flex-col"></div>
    </div>
    <PageFooter/>
  </div>
</template>

<script setup>

import PageHeader from "@/componentsPhone/PageHeader.vue";
import PageFooter from "@/componentsPhone/PageFooter.vue";
import GapView from "@/components/comment/GapView.vue";
import {onMounted} from "vue"
import { useStore } from 'vuex'
const store = useStore()
onMounted(function () {
  store.commit('changeCurrentMobiPage', 'contactPage')
})

const loopData0= [
  {
    title: 'Our Number',
    desc: '+65 6226 1666',
    image: require('./../assets/image/contact_icon_call.png'),
  },
  {
    title: 'Our Email',
    desc: 'info petrosunrich.com',
    image: require('./../assets/image/contact_icon_email.png'),
  },
  {
    title: 'Our Address',
    desc: '6 Shenton Way, OUE Downtown 2<br/>#20-11, Singapore 068809',
    image: require('./../assets/image/contact_icon_location.png'),
  },
]
</script>

<style scoped lang="scss">
.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  width: 19.20rem;
  overflow: hidden;
  .group_box_1 {
    width: 100%;
    height: 10.34rem;
    background-image: url("./../assets/image/adout_head_bg.png");
    background-size: auto 100%;
    background-position: center;
    .text-wrapper_head {
      width: 100%;
      height: 2.56rem;
      margin-top: 5.32rem;
      background-size: cover;
      position: relative;
      .text-wrapper_head_img {
        width: 17.72rem;
        height: 2.56rem;
        align-self: center;
        background-image: url("./../assets/image/text_img_stroke_CONTACT.png");
        background-size: auto 100%;
      }
      .text-wrapper_head_text {
        overflow-wrap: break-word;
        color: rgba(234, 88, 18, 1);
        font-size: 1.69rem;
        text-transform: uppercase;
        font-family: Montserrat-Black;
        white-space: nowrap;
        line-height: 2.05rem;
        position: absolute;
        align-self: center;
        bottom: -0.4rem;
      }
    }
  }

  .text-group_1 {
    width: 100%;
    .text_8 {
      overflow-wrap: break-word;
      color: rgba(89, 89, 89, 1);
      font-size: 0.92rem;
      text-transform: uppercase;
      font-family: Montserrat-Regular;
      line-height: 1.13rem;
      text-align: center;
      margin-top: 1.43rem;
    }

    .text_9 {
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 1.43rem;
      font-family: Montserrat-SemiBold;
      text-align: center;
      white-space: nowrap;
      line-height: 1.74rem;
      margin-top: 0.41rem;
    }

    .text_10 {
      width: 100%;
      overflow-wrap: break-word;
      color: rgba(89, 89, 89, 1);
      font-size: 0.82rem;
      font-family: Montserrat-Regular;
      text-align: center;
      line-height: 1.23rem;
      margin-top: 1.23rem;
    }
  }

  .list_1 {
    width: 100%;
    margin-top: 1.54rem;
    align-self: center;
    .list-items_1 {
      position: relative;
      width: 80%;
      align-self: center;
      .image_3 {
        width: 2.76rem;
        height: 2.76rem;
        background-size: cover;
        align-self: center;
      }
      .text_11 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 1.02rem;
        font-family: Montserrat-SemiBold;
        text-align: center;
        white-space: nowrap;
        line-height: 1.64rem;
      }
      .text_12 {
        overflow-wrap: break-word;
        color: rgba(89, 89, 89, 1);
        font-size: 0.82rem;
        font-family: Montserrat-Regular;
        text-align: center;
        line-height: 1.64rem;
        margin-top: 0.41rem;
      }
    }

  }

  .group_3 {
    width: 100%;
    padding-right: 0.82rem;
    padding-left: 0.82rem;
    margin-bottom: 2.46rem;

    .box_3 {
      border-radius: 0.28px;
      background-image: url("./../assets/image/contact_map.png");
      width: 100%;
      height: 12.19rem;
      align-self: center;
      background-size: auto 100%;
      background-position: center;
    }
  }
}

.footer {
  margin-top: 0.8rem;
}

</style>
