<template>
  <div :style="{height: scrollerHeight,width: scrollerHeight}" class="gap_c">
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue'
const props = defineProps({
  gap: {
    type: Number,
    default: 0
  }
})

const scrollerHeight = computed(function () {
  return props.gap + 'rem'
})
</script>
<style lang="scss" scoped>
.gap_c{
  display: block;
}
</style>
