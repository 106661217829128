<template>
  <div class="slide_group_c flex-col justify-between">
    <div class="box_1 flex-row justify-between">
      <TradingSlideButton name="Crude Oil" @click="OnClickType1(0)" :is-click="curry === 0"/>
      <TradingSlideButton name="LNG" @click="OnClickType1(1)" :is-click="curry === 1"/>
      <TradingSlideButton name="Fuel Oil" @click="OnClickType1(2)" :is-click="curry === 2"/>
      <TradingSlideButton name="Petroleum Products" @click="OnClickType1(3)" :is-click="curry === 3"/>
    </div>
    <div class="box_2 flex-row justify-between">
      <div class="group_3 flex-col">
        <img :src="curryData.img">
      </div>
      <div class="text-wrapper_8 flex-col">
        <span class="text_14">{{curryData.title}}</span>
        <span class="text_15">{{curryData.desc}}</span>
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed, onMounted, ref} from "vue";
import TradingSlideButton from "@/views/trading/TradingSlideButton.vue";

const datas = [
   {
     title: "Crude Oil",
     img: require("./../../assets/image/trading_pic_1.png"),
     desc: "Sunrich entered the crude oil trading market since its establishment and has fostered strong relationships with major oil producers and trading houses. Our trading team deals with numerous grades sourced through term supply contracts on a spot basis. We offer these grades to Chinese refineries through direct sales contracts."
   },
  {
    title: "LNG",
    img: require("./../../assets/image/trading_pic_2.png"),
    desc: "LNG supply and trading will be a critical component of Sunrich's future growth and expansion. We have established a Joint Venture Company with an active Chinese LNG distribution company to support our LNG trading. This venture will serve as the LNG sourcing platform to supply LNG into storage facilities and grid/pipelines in China. Additionally, we are preparing to enter into multiple Master Sales Agreements (MSAs) with leading international and major LNG producers and suppliers."
  },
   {
     title: "Fuel Oil",
     img: require("./../../assets/image/trading_pic_3.png"),
     desc: "Sunrich has emerged as a significant player in the fuel oil trading industry in recent years, forging strong partnerships and continuously expanding our activities in this domain."
   },
   {
     title: "Petroleum Products",
     img: require("./../../assets/image/trading_pic_4.png"),
     desc: "We also trade in petroleum products, as well as LPG, to capitalize on trading opportunities that meet our clients' needs while incorporating necessary risk management."
   }
]



const props = defineProps({
  index: {
    type: Number,
    default: 0
  }
})

onMounted(function () {
})


const firstInit = ref(true)
const curreyIndex = ref(0)

const curryData = computed(function () {
  if (firstInit.value) {
    return datas[props.index]
  }
  return datas[curreyIndex.value]
})
function OnClickType1(index) {
  curreyIndex.value = index
  firstInit.value = false
}

const curry = computed(function () {
  if (firstInit.value) {
    return props.index
  }
  return curreyIndex.value
})

</script>

<style scoped lang="scss">
.slide_group_c {
  width: 100%;
  .box_1 {
    width: 10.39rem;
    height: 0.56rem;
    margin-top: 0.48rem;
    align-self: center;
  }

  .box_2 {
    width: 14.40rem;
    height: 4.25rem;
    margin-top: 0.48rem;
    align-self: center;

    .group_3 {
      width: 6.10rem;
      height: 4.25rem;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        background-size: cover;
      }
    }
    .text-wrapper_8 {
      background-color: rgba(243, 251, 245, 1);
      width: 8.30rem;
      height: 4.25rem;

      .text_14 {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 0.24rem;
        font-family: Montserrat-SemiBold;
        text-align: left;
        white-space: nowrap;
        line-height: 0.34rem;
        margin: 1.20rem 0 0 0.48rem;
      }

      .text_15 {
        overflow-wrap: break-word;
        color: rgba(89, 89, 89, 1);
        font-size: 0.16rem;
        font-family: Montserrat-Regular;
        text-align: left;
        line-height: 0.32rem;
        margin: 0.24rem 0.24rem 0.24rem 0.48rem;
      }
    }
  }

}
</style>
