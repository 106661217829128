<template>
  <div class="home_block_3" id="flex_tag_1">
      <div class="home_box_1 flex-col">
        <transition name="fade">
          <div v-show="isVisibleComputed" class="text-wrapper_about flex-row">
            <span class="text_about">ABOUT SUNRICH</span>
          </div>
        </transition>
        <transition name="fade">
          <span  v-show="isVisibleComputed" class="text_13">Petro Sunrich Singapore Pte Ltd, established in Singapore in 2016, specializes in sourcing and supplying energy and commodities with a strong commitment to delivering these products responsibly.</span>
        </transition>
        <transition name="fade">
          <div v-show="isVisibleComputed" class="group_2 flex-col"></div>
        </transition>
    </div>
    <transition name="fade">
      <div v-show="isVisibleComputed" class="home_box_2 flex-col">
        <div class="box_9 flex-col"></div>
        <span class="text_14">Our team consists of seasoned traders and operations experts with nearly 20 years of experience in prominent state-owned oil companies, major trading houses, and financial institutions.</span>
      </div>
    </transition>

  </div>

</template>

<script setup>

import {computed, onMounted, onUnmounted, ref} from "vue";

const isVisible = ref(false);
const isFistShow = ref(true)

const isVisibleComputed = computed(function () {

  if (!isFistShow.value) {
    return true
  }
  return isFistShow.value && isVisible.value

})

onMounted(() => {
  window.addEventListener("scroll", scrollHandle)
});

onUnmounted(() => {
  window.removeEventListener("scroll", scrollHandleRemove)
});

function scrollHandleRemove() {

}

function scrollHandle() {
  const view = document.getElementById('flex_tag_1')
  if (view) {
    const elementTop = view.getBoundingClientRect().top
    const elementBottom = view.getBoundingClientRect().bottom

    if (elementTop <= window.innerHeight && elementBottom >= 0) {
      isVisible.value = true
    } else {
      if (isVisible.value) {
        isFistShow.value = false
      }
      isVisible.value = false
    }
  }
}


</script>
<style scoped lang="scss">
.home_block_3 {
  background-color: rgba(255, 255, 255, 1);
  width: 19.20rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.home_box_1 {
  width: 9.60rem;
  height: 10.80rem;
  padding-left: 2.4rem;
  .text-wrapper_about {
    width: 5.61rem;
    height: 1.09rem;
    margin-top: 0.83rem;
    text-align: left;
    background-image: url("./../../assets/image/text_img_stroke_about.png");
    background-size: contain;
    .text_about {
      color: rgba(234, 88, 18, 1);
      font-size: 0.36rem;
      font-family: Montserrat-Black;
      align-self: end;
      margin-bottom: -0.05rem;
    }
  }
  .text_13 {
    width: 6.10rem;
    height: 1.52rem;
    overflow-wrap: break-word;
    color: rgba(89, 89, 89, 1);
    font-size: 0.18rem;
    font-family: Montserrat-Regular;
    text-align: left;
    line-height: 0.38rem;
    margin-top: 0.22rem;
  }
  .group_2 {
    width: 6.01rem;
    height: 6.01rem;
    background-image: url("./../../assets/image/home_img_pic_1.png");
    background-size: cover;
    margin-top: 0.48rem;
  }
}
.home_box_2 {
  width: 9.60rem;
  height: 10.80rem;
  position: relative;
  .box_9 {
    width: 7.32rem;
    height: 5.63rem;
    background-image: url("./../../assets/image/home_img_people.png");
    background-size: cover;
    margin-top: 2.1rem;
  }
  .text_14 {
    width: 70%;
    overflow-wrap: break-word;
    color: rgba(71, 71, 71, 1);
    font-size: 0.18rem;
    font-family: Montserrat-Regular;
    text-align: left;
    line-height: 0.38rem;
    margin-top: 0.32rem;
  }

}

.fade-enter-active {
  animation: fadeInUp;
  animation-duration: 2s;
}
.fade-enter{
  opacity: 0;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

</style>
